<template>
  <div>
    <NuxtLoadingIndicator color="black" />
    <CookieControl :locale="locale"></CookieControl>
    <UNotifications />
    <NotificationNotifications />
    <UiRegisterNotification />
    <UiAlert
      :show="store.isVisible"
      :messages="store.messages"
      @close="store.setVisible(false)"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useAlertsStore } from '~/stores/alerts'

const store = useAlertsStore()

const { locale, t } = useI18n()
const { defaultOgImage } = useOgImage()

useSeoMeta({
  title: 'LD Seating',
  description: t('pages.index.meta.description'),
  ogImage: defaultOgImage,
})

const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl()

watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (
      !previous?.includes('google-analytics') &&
      current?.includes('google-analytics')
    ) {
      // cookie with id `google-analytics` got added
      window.location.reload() // placeholder for your custom change handler
    }
  },
  { deep: true }
)

onMounted(() => {
  // TODO: Drop in future
  localStorage['nuxt-color-mode'] = 'light'
})
</script>

<style lang="scss">
::selection {
  background-color: #000;
  color: #fff;
}

.bg-black {
  ::selection {
    background-color: #fff;
    color: #000;
  }
}

@font-face {
  font-family: 'Pragmatica';
  src:
    local('Pragmatica Book'),
    local('Pragmatica-Book'),
    url('/fonts/pragmatica-book.woff2') format('woff2'),
    url('/fonts/pragmatika-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pragmatica';
  src:
    local('Pragmatica Bold'),
    local('Pragmatica-Bold'),
    url('/fonts/pragmatica-bold.woff2') format('woff2'),
    url('/fonts/pragmatika-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pragmatica Ext';
  src:
    local('Pragmatica Ext Book'),
    local('PragmaticaExt-Book'),
    url('/fonts/pragmatica-ext-book.woff2') format('woff2'),
    url('/fonts/pragmatica-ext-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pragmatica Ext';
  src:
    local('Pragmatica Ext Bold'),
    local('PragmaticaExt-Bold'),
    url('/fonts/pragmatica-ext-bold.woff2') format('woff2'),
    url('/fonts/pragmatica-ext-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.sl-overlay {
  background-color: black !important;
}

.sl-wrapper .sl-close {
  color: white !important;
}

.sl-wrapper .sl-navigation button {
  color: white !important;
}
</style>
