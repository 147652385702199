import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-right",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "en": "This cookie stores preferences.",
          "cs": "Tento soubor cookie ukládá vaše preference."
        },
        "id": "p",
        "name": {
          "en": "Preferences",
          "cs": "Preference"
        },
        "src": "https://example.com/preferences/js?id=<API-KEY>",
        "targetCookieIds": [
          "xmpl_a",
          "xmpl_b"
        ]
      }
    ],
    "optional": [
      {
        "description": {
          "en": "This cookie is used for Google Analytics.",
          "cs": "Tento soubor Cookie je používán Google Analytics."
        },
        "id": "gtag",
        "name": {
          "en": "Analytical cookies",
          "cs": "Analytické cookies"
        },
        "src": "https://example.com/preferences/js?id=<API-KEY>",
        "targetCookieIds": [
          "gtag"
        ]
      },
      {
        "description": {
          "en": "This cookie is used for Advertising purposes.",
          "cs": "Tento soubor Cookie je používán pro reklamní účely."
        },
        "id": "hotjar",
        "name": {
          "en": "Advertising cookies",
          "cs": "Reklamní cookies"
        },
        "src": "https://example.com/preferences/js?id=<API-KEY>",
        "targetCookieIds": [
          "hotjar"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "cs",
    "de",
    "fr",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Přijmout",
      "acceptAll": "Přijmout vše",
      "bannerDescription": "Používáme soubory cookie a cookie třetích stran, abychom mohli vše správně zobrazovat a lépe porozumět tomu, jak tento web používáte, s cílem zlepšit nabízené služby. Rozhodnutí lze kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.",
      "bannerTitle": "Cookies",
      "close": "Zavřít",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nezbytné cookies",
      "cookiesOptional": "Volitelné cookies",
      "decline": "Zamítnout",
      "declineAll": "Zamítnout vše",
      "here": "zde",
      "iframeBlocked": "Chcete-li ji zobrazit, povolte obslužné cookies",
      "manageCookies": "Další informace a přizpůsobení",
      "save": "Uložit",
      "settingsUnsaved": "Máte neuložená nastavení"
    },
    "cs": {
      "accept": "Přijmout",
      "acceptAll": "Přijmout vše",
      "bannerDescription": "Používáme soubory cookie a cookie třetích stran, abychom mohli vše správně zobrazovat a lépe porozumět tomu, jak tento web používáte, s cílem zlepšit nabízené služby. Rozhodnutí lze kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.",
      "bannerTitle": "Cookies",
      "close": "Zavřít",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nezbytné cookies",
      "cookiesOptional": "Volitelné cookies",
      "decline": "Zamítnout",
      "declineAll": "Zamítnout vše",
      "here": "zde",
      "iframeBlocked": "Chcete-li ji zobrazit, povolte obslužné cookies",
      "manageCookies": "Další informace a přizpůsobení",
      "save": "Uložit",
      "settingsUnsaved": "Máte neuložená nastavení"
    },
    "de": {
      "accept": "Přijmout",
      "acceptAll": "Přijmout vše",
      "bannerDescription": "Používáme soubory cookie a cookie třetích stran, abychom mohli vše správně zobrazovat a lépe porozumět tomu, jak tento web používáte, s cílem zlepšit nabízené služby. Rozhodnutí lze kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.",
      "bannerTitle": "Cookies",
      "close": "Zavřít",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nezbytné cookies",
      "cookiesOptional": "Volitelné cookies",
      "decline": "Zamítnout",
      "declineAll": "Zamítnout vše",
      "here": "zde",
      "iframeBlocked": "Chcete-li ji zobrazit, povolte obslužné cookies",
      "manageCookies": "Další informace a přizpůsobení",
      "save": "Uložit",
      "settingsUnsaved": "Máte neuložená nastavení"
    },
    "fr": {
      "accept": "Přijmout",
      "acceptAll": "Přijmout vše",
      "bannerDescription": "Používáme soubory cookie a cookie třetích stran, abychom mohli vše správně zobrazovat a lépe porozumět tomu, jak tento web používáte, s cílem zlepšit nabízené služby. Rozhodnutí lze kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.",
      "bannerTitle": "Cookies",
      "close": "Zavřít",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nezbytné cookies",
      "cookiesOptional": "Volitelné cookies",
      "decline": "Zamítnout",
      "declineAll": "Zamítnout vše",
      "here": "zde",
      "iframeBlocked": "Chcete-li ji zobrazit, povolte obslužné cookies",
      "manageCookies": "Další informace a přizpůsobení",
      "save": "Uložit",
      "settingsUnsaved": "Máte neuložená nastavení"
    }
  }
} as ModuleOptions