import Locales, { ApiLocale, NuxtLocale } from '~/constants/locales'

function convertApiLocale(apiLocale: ApiLocale): NuxtLocale {
  const keys = Object.keys(Locales) as Array<keyof typeof Locales>
  for (const key of keys) {
    if (ApiLocale[key] === apiLocale) {
      return NuxtLocale[key]
    }
  }
  return NuxtLocale[Locales.EU_en]
}

function convertNuxtLocale(nuxtLocale: NuxtLocale): ApiLocale {
  const keys = Object.keys(Locales) as Array<keyof typeof Locales>
  for (const key of keys) {
    if (NuxtLocale[key] === nuxtLocale) {
      return ApiLocale[key]
    }
  }
  return ApiLocale[Locales.EU_en]
}

export { convertApiLocale, convertNuxtLocale }
