<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { NuxtLocale } from '~/constants/locales'
import { notificationKind, notificationShowTo } from '~/constants/notifications'
import { useUser } from '~/stores/user'
import type { Notification } from '~/types/api'
import { useNotificationsStore } from '~/stores/notifications'

const route = useRoute()

function removeLocalePrefix(path: string): string {
  const pattern = new RegExp(`^/(${Object.values(NuxtLocale).join('|')})`)
  path = path.replace(pattern, '')
  if (path === '') return '/'
  return path
}

const notificationsRepository = createNotificationsRepository()

const { data: notifications, refresh } = await useAsyncData(() =>
  // fail silently
  notificationsRepository.getCurrentForRoute(removeLocalePrefix(route.path))
)

watch(
  () => route.path,
  () => refresh()
)

const notificationsStore = useNotificationsStore()
const { wasNotificationDisplayedRecently } = notificationsStore
const userStore = useUser()
const { isLoggedIn } = storeToRefs(userStore)

const userNotifications = computed(() => {
  const visibleForUser = (notifications.value?.items ?? []).filter(
    (notification: Notification) => {
      return (
        !wasNotificationDisplayedRecently(notification) &&
        (isLoggedIn.value ||
          notification.showTo !== notificationShowTo.SIGNED_IN_USERS)
      )
    }
  )

  // get up to 1 notification for each kind
  return [
    ...new Map(
      visibleForUser.map((notification: Notification) => [
        notification.kind,
        notification,
      ])
    ).values(),
  ]
})
</script>

<template>
  <div>
    <div v-for="notification in userNotifications" :key="notification.nid">
      <NotificationNotice
        v-if="notification.kind === notificationKind.NOTIFICATION"
        :notification="notification"
      />
      <NotificationModal
        v-else-if="notification.kind === notificationKind.MODAL"
        :notification="notification"
      />
      <NotificationTopBar
        v-else-if="notification.kind === notificationKind.TOP_BAR"
        :notification="notification"
      />
    </div>
  </div>
</template>

<style scoped></style>
