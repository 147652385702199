import { defineStore } from 'pinia'
import type { Notification } from '~/types/api'
import {
  notificationKind,
  notificationShowWhen,
} from '~/constants/notifications'

const ONE_DAY = 60 * 60 * 24

export const useNotificationsStore = defineStore('notifications', () => {
  const registerNotification = ref(false)

  const registerNotificationClosed = useCookie<string | undefined>(
    'register-notification-closed',
    { maxAge: 60 * 60 * 24 /* 24 hours */ }
  )
  function showRegisterNotification(value: boolean): void {
    if (value) {
      if (!registerNotificationClosed.value) {
        // wait some time after user closes the notification before displaying it again
        registerNotification.value = true
      }
    }
    if (!value) {
      registerNotificationClosed.value = 'true'
      registerNotification.value = false
    }
  }

  function getNotificationCookie(notification: Notification) {
    let maxAge = 10
    if (notification.showWhen === notificationShowWhen.ONCE_A_DAY) {
      maxAge = ONE_DAY /* 24 hours */
    }
    if (notification.showWhen === notificationShowWhen.ONCE_A_WEEK) {
      maxAge = 7 * ONE_DAY
    }
    if (notification.showWhen === notificationShowWhen.ONCE_A_MONTH) {
      maxAge = 30 * ONE_DAY
    }
    return useCookie<{ showWhen: string } | undefined>(
      `notification-${notification.nid}`,
      { maxAge }
    )
  }

  function wasNotificationDisplayedRecently(
    notification: Notification
  ): boolean {
    const cookieValue = getNotificationCookie(notification)
    if (cookieValue.value?.showWhen !== notification.showWhen) {
      // reset cookie when show when has changed
      cookieValue.value = undefined
      return false
    }
    return !!cookieValue.value
  }

  function closeNotification(notification: Notification): void {
    if (
      notification.showWhen === notificationShowWhen.EVERYTIME &&
      notification.kind !== notificationKind.MODAL
    ) {
      return
    }

    const notificationCookie = getNotificationCookie(notification)
    notificationCookie.value = {
      showWhen: notification.showWhen ?? notificationShowWhen.EVERYTIME,
    }
  }

  return {
    registerNotification,
    showRegisterNotification,
    getNotificationCookie,
    wasNotificationDisplayedRecently,
    closeNotification,
  }
})
