<script setup lang="ts">
const props = defineProps<{
  loading?: boolean
}>()
</script>

<template>
  <template v-if="props.loading">
    <UiSpinner />
  </template>
  <div v-else class="group relative text-xl">
    <slot />
    <div
      class="absolute bottom-[-2px] h-[1px] w-full bg-black transition-all duration-300 group-hover:bottom-[-8px]"
    />
  </div>
</template>
