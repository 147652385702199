import type { Currency } from '~/types/api'

export const PRICE_EMPTY = '-'

export function priceWithVat(price: number, count: number = 1) {
  return (price + price * 0.21) * count
}

export function formatPrice(
  price: number | null | undefined,
  currency: Currency | null | undefined
) {
  if (
    price === null ||
    price === undefined ||
    !currency ||
    !currency.format ||
    !currency.sign
  ) {
    return PRICE_EMPTY
  }

  // Have to round same way as current FE which uses this function in some cases
  if (currency.decimalPlaces === 0) {
    price = roundLikePHP(price, 0)
  }

  const result = price
    .toFixed(currency?.decimalPlaces ?? 2)
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ')

  return currency.format
    .replace('%number', result)
    .replace('%sign', currency.sign)
}

function roundLikePHP(number: number, decimalPlaces: number) {
  const numSign = number >= 0 ? 1 : -1
  return parseFloat(
    (
      Math.round(number * Math.pow(10, decimalPlaces) + numSign * 0.0001) /
      Math.pow(10, decimalPlaces)
    ).toFixed(decimalPlaces)
  )
}
