import { default as indexnDwuT3WBtvMeta } from "/builds/ldseating/ldseating-frontend/pages/index.vue?macro=true";
import { default as _91slug_93YdYDpwUp7QMeta } from "/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue?macro=true";
import { default as _91slug_93oSiDFeoj16Meta } from "/builds/ldseating/ldseating-frontend/pages/[slug].vue?macro=true";
import { default as aboutD8YXp7MjKOMeta } from "/builds/ldseating/ldseating-frontend/pages/about.vue?macro=true";
import { default as careersji8D5ljHmaMeta } from "/builds/ldseating/ldseating-frontend/pages/careers.vue?macro=true";
import { default as contact1GPE4oyqhcMeta } from "/builds/ldseating/ldseating-frontend/pages/contact.vue?macro=true";
import { default as customer_45serviceHKdIkSKoIaMeta } from "/builds/ldseating/ldseating-frontend/pages/customer-service.vue?macro=true";
import { default as _91slug_93evAexx91X0Meta } from "/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue?macro=true";
import { default as designersQXnBaMabQOMeta } from "/builds/ldseating/ldseating-frontend/pages/designers.vue?macro=true";
import { default as downloadCiIjtNUkvtMeta } from "/builds/ldseating/ldseating-frontend/pages/download.vue?macro=true";
import { default as email_45verifiedImzLURSzEWMeta } from "/builds/ldseating/ldseating-frontend/pages/email-verified.vue?macro=true";
import { default as eu_45supportDlaX5NLspsMeta } from "/builds/ldseating/ldseating-frontend/pages/eu-support.vue?macro=true";
import { default as logintdE3q5dc2wMeta } from "/builds/ldseating/ldseating-frontend/pages/login.vue?macro=true";
import { default as lookbookTKVZFPJGdkMeta } from "/builds/ldseating/ldseating-frontend/pages/lookbook.vue?macro=true";
import { default as _91slug_93XqUnvcc1weMeta } from "/builds/ldseating/ldseating-frontend/pages/news/[slug].vue?macro=true";
import { default as index4019zCIFQhMeta } from "/builds/ldseating/ldseating-frontend/pages/news/index.vue?macro=true";
import { default as _91nid_93WrgiNjxFaaMeta } from "/builds/ldseating/ldseating-frontend/pages/node/[nid].vue?macro=true";
import { default as ordersIJJeUKrTwIMeta } from "/builds/ldseating/ldseating-frontend/pages/orders.vue?macro=true";
import { default as _91id_931j9XMqlZVYMeta } from "/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue?macro=true";
import { default as _91_46_46_46slug_93EnfztzjOrxMeta } from "/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue?macro=true";
import { default as indexzocfGm5COIMeta } from "/builds/ldseating/ldseating-frontend/pages/press/index.vue?macro=true";
import { default as privacy_45policy3lwBveZpZuMeta } from "/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93jJOWnFVgX8Meta } from "/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue?macro=true";
import { default as product_45linesI2kvbJp6BqMeta } from "/builds/ldseating/ldseating-frontend/pages/product-lines.vue?macro=true";
import { default as _91slug_938vmUPLvIllMeta } from "/builds/ldseating/ldseating-frontend/pages/products/[slug].vue?macro=true";
import { default as productsPdwqwUWyOGMeta } from "/builds/ldseating/ldseating-frontend/pages/products.vue?macro=true";
import { default as _91slug_93ss7lr2aGx9Meta } from "/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue?macro=true";
import { default as _91nid_93YxwC1SMn9CMeta } from "/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue?macro=true";
import { default as indexDFOYVmKuQMMeta } from "/builds/ldseating/ldseating-frontend/pages/references/index.vue?macro=true";
import { default as sales_45conditions8myQqALIqZMeta } from "/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue?macro=true";
import { default as searchnTGOT7KqqxMeta } from "/builds/ldseating/ldseating-frontend/pages/search.vue?macro=true";
import { default as testedme0pJtiEMeta } from "/builds/ldseating/ldseating-frontend/pages/test.vue?macro=true";
import { default as _91code_93JDXiQIzWFLMeta } from "/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue?macro=true";
import { default as _91hash_93hK4pwlKOYTMeta } from "/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue?macro=true";
import { default as index6VpUyIdZHkMeta } from "/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue?macro=true";
import { default as component_45stubIqDJ1T2AxtMeta } from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubIqDJ1T2Axt } from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "line-slug___cs",
    path: "/cs/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "line-slug___en",
    path: "/en/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "line-slug___de",
    path: "/de/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "line-slug___fr",
    path: "/fr/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___cs",
    path: "/cs/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about-us",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/ueber-uns",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/notre-societe",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "careers___cs",
    path: "/cs/kariera",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___de",
    path: "/de/karriere",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___fr",
    path: "/fr/carri%C3%A8re",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___cs",
    path: "/cs/reklamace",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___en",
    path: "/en/customer-service",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___de",
    path: "/de/kundenservice",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___fr",
    path: "/fr/reclamation",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "designer-slug___cs",
    path: "/cs/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "designer-slug___en",
    path: "/en/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "designer-slug___de",
    path: "/de/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "designer-slug___fr",
    path: "/fr/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "designers___cs",
    path: "/cs/designeri",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue").then(m => m.default || m)
  },
  {
    name: "designers___en",
    path: "/en/designers",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue").then(m => m.default || m)
  },
  {
    name: "designers___de",
    path: "/de/designer",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue").then(m => m.default || m)
  },
  {
    name: "designers___fr",
    path: "/fr/createurs",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue").then(m => m.default || m)
  },
  {
    name: "download___cs",
    path: "/cs/ke-stazeni",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "download___en",
    path: "/en/downloads",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "download___de",
    path: "/de/downloads",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "download___fr",
    path: "/fr/a-telecharger",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "email-verified___cs",
    path: "/cs/email-overen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "email-verified___en",
    path: "/en/email-verified",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "email-verified___de",
    path: "/de/email-uberpruft",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "email-verified___fr",
    path: "/fr/email-verifie",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "eu-support___cs",
    path: "/cs/podpora-eu",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue").then(m => m.default || m)
  },
  {
    name: "eu-support___en",
    path: "/en/eu-support",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue").then(m => m.default || m)
  },
  {
    name: "eu-support___de",
    path: "/de/eu-foerderprogramme",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue").then(m => m.default || m)
  },
  {
    name: "eu-support___fr",
    path: "/fr/soutien-de-l-union-europeenne",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "lookbook___cs",
    path: "/cs/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue").then(m => m.default || m)
  },
  {
    name: "lookbook___en",
    path: "/en/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue").then(m => m.default || m)
  },
  {
    name: "lookbook___de",
    path: "/de/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue").then(m => m.default || m)
  },
  {
    name: "lookbook___fr",
    path: "/fr/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue").then(m => m.default || m)
  },
  {
    name: "news-slug___cs",
    path: "/cs/novinky/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___de",
    path: "/de/nachrichten/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___fr",
    path: "/fr/nouvelles/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news___cs",
    path: "/cs/novinky",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___fr",
    path: "/fr/nouvelles",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "node-nid___cs",
    path: "/cs/node/:nid()",
    meta: _91nid_93WrgiNjxFaaMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue").then(m => m.default || m)
  },
  {
    name: "node-nid___en",
    path: "/en/node/:nid()",
    meta: _91nid_93WrgiNjxFaaMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue").then(m => m.default || m)
  },
  {
    name: "node-nid___de",
    path: "/de/node/:nid()",
    meta: _91nid_93WrgiNjxFaaMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue").then(m => m.default || m)
  },
  {
    name: "node-nid___fr",
    path: "/fr/node/:nid()",
    meta: _91nid_93WrgiNjxFaaMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue").then(m => m.default || m)
  },
  {
    name: "orders___cs",
    path: "/cs/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___de",
    path: "/de/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___fr",
    path: "/fr/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "pdf-id___cs",
    path: "/cs/pdf/:id()",
    meta: _91id_931j9XMqlZVYMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue").then(m => m.default || m)
  },
  {
    name: "pdf-id___en",
    path: "/en/pdf/:id()",
    meta: _91id_931j9XMqlZVYMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue").then(m => m.default || m)
  },
  {
    name: "pdf-id___de",
    path: "/de/pdf/:id()",
    meta: _91id_931j9XMqlZVYMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue").then(m => m.default || m)
  },
  {
    name: "pdf-id___fr",
    path: "/fr/pdf/:id()",
    meta: _91id_931j9XMqlZVYMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue").then(m => m.default || m)
  },
  {
    name: "press-slug___cs",
    path: "/cs/pro-media/:slug(.*)*",
    meta: _91_46_46_46slug_93EnfztzjOrxMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "press-slug___en",
    path: "/en/press/:slug(.*)*",
    meta: _91_46_46_46slug_93EnfztzjOrxMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "press-slug___de",
    path: "/de/presse/:slug(.*)*",
    meta: _91_46_46_46slug_93EnfztzjOrxMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "press-slug___fr",
    path: "/fr/presse/:slug(.*)*",
    meta: _91_46_46_46slug_93EnfztzjOrxMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "press___cs",
    path: "/cs/pro-media",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___en",
    path: "/en/press",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___de",
    path: "/de/presse",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___fr",
    path: "/fr/presse",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___cs",
    path: "/cs/ochrana-osobnich-udaju",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/de/datenschutzbereich",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/politique-de-confidentialite",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-lines___cs",
    path: "/cs/produktove-rady",
    meta: product_45linesI2kvbJp6BqMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue").then(m => m.default || m),
    children: [
  {
    name: "product-lines-category-slug___cs",
    path: "kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "product-lines___en",
    path: "/en/product-lines",
    meta: product_45linesI2kvbJp6BqMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue").then(m => m.default || m),
    children: [
  {
    name: "product-lines-category-slug___en",
    path: "category/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "product-lines___de",
    path: "/de/produktlinien",
    meta: product_45linesI2kvbJp6BqMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue").then(m => m.default || m),
    children: [
  {
    name: "product-lines-category-slug___de",
    path: "kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "product-lines___fr",
    path: "/fr/series-de-produits",
    meta: product_45linesI2kvbJp6BqMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue").then(m => m.default || m),
    children: [
  {
    name: "product-lines-category-slug___fr",
    path: "categorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "products___cs",
    path: "/cs/produkty",
    meta: productsPdwqwUWyOGMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-slug___cs",
    path: ":slug()",
    meta: _91slug_938vmUPLvIllMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "products___en",
    path: "/en/products",
    meta: productsPdwqwUWyOGMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-slug___en",
    path: ":slug()",
    meta: _91slug_938vmUPLvIllMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "products___de",
    path: "/de/produkte",
    meta: productsPdwqwUWyOGMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-slug___de",
    path: ":slug()",
    meta: _91slug_938vmUPLvIllMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "products___fr",
    path: "/fr/produits",
    meta: productsPdwqwUWyOGMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-slug___fr",
    path: ":slug()",
    meta: _91slug_938vmUPLvIllMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "reference-slug___cs",
    path: "/cs/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reference-slug___en",
    path: "/en/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reference-slug___de",
    path: "/de/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reference-slug___fr",
    path: "/fr/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: "references-categories-nid___cs",
    path: "/cs/reference/kategorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue").then(m => m.default || m)
  },
  {
    name: "references-categories-nid___en",
    path: "/en/references/category/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue").then(m => m.default || m)
  },
  {
    name: "references-categories-nid___de",
    path: "/de/referenzen/kategorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue").then(m => m.default || m)
  },
  {
    name: "references-categories-nid___fr",
    path: "/fr/references/categorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue").then(m => m.default || m)
  },
  {
    name: "references___cs",
    path: "/cs/reference",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue").then(m => m.default || m)
  },
  {
    name: "references___en",
    path: "/en/references",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue").then(m => m.default || m)
  },
  {
    name: "references___de",
    path: "/de/referenzen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue").then(m => m.default || m)
  },
  {
    name: "references___fr",
    path: "/fr/references",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-conditions___cs",
    path: "/cs/obchodni-podminky",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue").then(m => m.default || m)
  },
  {
    name: "sales-conditions___en",
    path: "/en/sales-conditions",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue").then(m => m.default || m)
  },
  {
    name: "sales-conditions___de",
    path: "/de/geschaeftsbedingungen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue").then(m => m.default || m)
  },
  {
    name: "sales-conditions___fr",
    path: "/fr/termes-et-conditions",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue").then(m => m.default || m)
  },
  {
    name: "search___cs",
    path: "/cs/vyhledavani",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "test___cs",
    path: "/cs/test",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en",
    path: "/en/test",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___de",
    path: "/de/test",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___fr",
    path: "/fr/test",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "user-activation-code___cs",
    path: "/cs/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue").then(m => m.default || m)
  },
  {
    name: "user-activation-code___en",
    path: "/en/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue").then(m => m.default || m)
  },
  {
    name: "user-activation-code___de",
    path: "/de/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue").then(m => m.default || m)
  },
  {
    name: "user-activation-code___fr",
    path: "/fr/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue").then(m => m.default || m)
  },
  {
    name: "user-passwordReset-hash___cs",
    path: "/cs/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "user-passwordReset-hash___en",
    path: "/en/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "user-passwordReset-hash___de",
    path: "/de/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "user-passwordReset-hash___fr",
    path: "/fr/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "user-profile___cs",
    path: "/cs/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___en",
    path: "/en/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubIqDJ1T2AxtMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubIqDJ1T2Axt
  }
]