
import * as ldseatingRuntime$eUnNcmE3Kw from '/builds/ldseating/ldseating-frontend/providers/ldseating.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ldseating",
  "domains": [
    "ldseating.jagu.dev",
    "new.ldseating.jagu.dev",
    "api.ldseating.jagu.dev",
    "ldseating.com",
    "new.ldseating.com",
    "api.ldseating.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ldseating']: { provider: ldseatingRuntime$eUnNcmE3Kw, defaults: {"baseURL":"https://api.ldseating.jagu.dev"} }
}
        