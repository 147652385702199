<script setup lang="ts">
import type { ProductLineInList } from '~/types/api'

const props = defineProps<{
  productLine: ProductLineInList
  dark?: boolean
  dense?: boolean
}>()

const localePath = useLocalePath()

const { getResourceUrl, getImageSrcSet } = useResources()

const image = computed(() => props.productLine?.thumbnailImages?.[0])
const hoverImage = computed(() => props.productLine?.thumbnailImages?.[1])

const srcset = getImageSrcSet(image.value?.url)
const hoverSrcset = hoverImage.value
  ? getImageSrcSet(hoverImage.value?.url)
  : undefined
</script>

<template>
  <div class="group p-3" :class="{ 'bg-neutral-100': props.dark }">
    <div
      class="relative m-0 block h-full select-none overflow-hidden hover:!bg-white"
    >
      <NuxtLink
        class="no-underline"
        :to="
          localePath({
            name: 'slug',
            params: { slug: props.productLine.urlSlug },
          })
        "
      >
        <div :class="{ 'flex items-center gap-10 md:block': dense }">
          <div v-if="props.dense" class="font-pragmatica-ext text-xl font-bold">
            {{ productLine.title }}
          </div>
          <div
            class="before:content[''] relative z-0 my-0 block h-full w-full overflow-hidden before:block before:pt-[75%] md:my-10"
          >
            <img
              :src="getResourceUrl(image?.url + '/32x32/FIT')"
              alt=""
              title=""
              class="absolute bottom-0 left-0 top-0 z-0 h-full w-full border-0 object-cover blur-lg"
              loading="eager"
              aria-hidden="true"
            />
            <img
              :alt="image?.metaAlt"
              :title="image?.metaTitle"
              class="absolute bottom-0 left-0 top-0 z-0 h-full w-full border-0 object-cover group-hover:bg-white"
              :class="{ 'opacity-0 group-hover:opacity-100': hoverImage }"
              loading="lazy"
              sizes="(min-width: 768px) 992px, 100vw"
              :srcset="srcset"
              :src="getResourceUrl(image?.url + '/640x640/FIT')"
              data-ll-status="loaded"
            />
            <img
              v-if="hoverImage"
              :alt="hoverImage?.metaAlt"
              :title="hoverImage?.metaTitle"
              class="op absolute bottom-0 left-0 top-0 z-0 h-full w-full border-0 bg-neutral-100 object-cover group-hover:opacity-0"
              loading="lazy"
              sizes="(min-width: 768px) 992px, 100vw"
              :srcset="hoverSrcset"
              :src="getResourceUrl(hoverImage?.url + '/640x640/FIT')"
              data-ll-status="loaded"
            />
          </div>
          <div
            v-if="!props.dense"
            class="pb-5 text-center transition-colors duration-300"
          >
            <div>
              <div class="font-pragmatica-ext mb-4 text-2xl font-bold">
                {{ productLine.title }}
              </div>
            </div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped></style>
