<script lang="ts" setup>
import type { FatalError } from '~/utils/errors'

const error = useError()
const localePath = useLocalePath()

console.error(error.value?.message)

const { t } = useI18n()

const errorData = computed(() => {
  let newErrorData = {} as FatalError
  if (!error.value?.data) {
    newErrorData.translatedError =
      error.value?.message ?? t('error.somethingWentWrong')
    newErrorData.status = error.value?.statusCode ?? 500
    newErrorData.link = 'index'
    newErrorData.linkName = 'error.backToHomepage'
  } else {
    newErrorData = JSON.parse(error.value.data)
  }
  return newErrorData
})

function handleError() {
  clearError({
    redirect: localePath({ name: errorData.value.link }),
  })
}
</script>

<template>
  <div class="flex min-h-screen flex-col justify-between">
    <UiHeader />
    <UiBanner :title="$t('error.somethingWentWrong')" />
    <div
      class="grid place-content-center overflow-hidden bg-white p-10 py-28 text-black antialiased dark:bg-black dark:text-white"
    >
      <div v-if="error" class="my-8">
        <h1 v-if="error.statusCode" class="text-8xl font-bold">
          {{ errorData.status }}
        </h1>
        <h2 class="max-w-2xl text-3xl font-bold">
          {{ errorData.translatedError }}
        </h2>
      </div>
      <button
        class="flex w-fit cursor-pointer bg-black px-4 py-2 font-bold text-white dark:bg-white dark:text-black"
        @click="handleError"
      >
        <p class="text-pragmatica-extended text-3xl">
          {{ $t(errorData.linkName) }}
        </p>
      </button>
    </div>
    <UiFooter />
  </div>
</template>

<style scoped></style>
