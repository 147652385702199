<script setup lang="ts">
import { useAlertsStore } from '~/stores/alerts'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  titlePath: {
    type: String,
    default: null,
  },
  titleClass: {
    type: String,
    default: 'text-2xl',
  },
  closeable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

// prevent close of first modal when alert is shown
const alertsStore = useAlertsStore()

const isOpen = computed({
  get: () => {
    return props.modelValue
  },
  set: (newValue: boolean) => {
    emit('update:modelValue', newValue)
  },
})
</script>

<template>
  <ClientOnly>
    <UModal
      v-model="isOpen"
      :prevent-close="alertsStore.isVisible"
      :ui="{ width: 'w-full sm:max-w-screen-sm', rounded: 'rounded-none' }"
    >
      <div>
        <div class="mx-auto bg-white p-10" @click.stop>
          <div v-if="titlePath || closeable" class="flex items-start justify-between pb-10">
            <h3 v-if="titlePath" class="font-bold" :class="titleClass">
              {{ $t(titlePath) }}
            </h3>
            <button
              v-if="closeable"
              type="button"
              class="ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-neutral-400 hover:bg-neutral-200 hover:text-neutral-900 dark:hover:bg-neutral-600 dark:hover:text-white"
              @click="isOpen = false"
            >
              <svg
                class="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <slot />
        </div>
      </div>
    </UModal>
  </ClientOnly>
</template>

<style scoped></style>
