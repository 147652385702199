import routerOptions0 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/ldseating/ldseating-frontend/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}