<script setup lang="ts">
import type { Notification } from '~/types/api'
import { useNotificationsStore } from '~/stores/notifications'

const props = defineProps<{ notification: Notification }>()

const notificationsStore = useNotificationsStore()
const { closeNotification } = notificationsStore

const route = useRoute()
watch(
  () => route.path,
  () => closeNotification(props.notification)
)
</script>

<template>
  <section>
    <div
      class="container flex h-full max-w-full items-center justify-between bg-black px-10 text-xl font-bold text-white lg:px-24"
    >
      <NuxtLink class="my-2 no-underline" :to="notification.buttonLink">
        <span class="underline">
          {{ notification.title }}
        </span>
      </NuxtLink>
      <NuxtLink class="my-3 no-underline" :to="notification.buttonLink">
        <div
          v-if="notification.buttonText"
          class="whitespace-nowrap border-2 border-white px-3 py-1 hover:bg-white hover:text-black max-sm:hidden"
        >
          {{ notification.buttonText }}
        </div>
        <Icon class="sm:!hidden" name="fa6-solid:chevron-right" size="30" />
      </NuxtLink>
    </div>
  </section>
</template>

<style scoped></style>
