<script setup lang="ts">
import type { Notification } from '~/types/api'
import { notificationPosition } from '~/constants/notifications'
import { useNotificationsStore } from '~/stores/notifications'

const props = defineProps<{ notification: Notification }>()

const isOpen = ref(true)

function isPosition(position: string): boolean {
  return props.notification.position === position
}

function getTransform(): string {
  // position LEFT or position RIGHT
  let xTranslate = 0
  let yTranslate = -50

  if (
    isPosition(notificationPosition.TOP) ||
    isPosition(notificationPosition.BOTTOM)
  ) {
    xTranslate = -50
    yTranslate = 0
  }

  return `translate(${xTranslate}%, ${yTranslate}%)`
}

const notificationsStore = useNotificationsStore()
const { closeNotification } = notificationsStore

function close() {
  isOpen.value = false
  closeNotification(props.notification)
}
</script>

<template>
  <div
    v-if="isOpen"
    class="fixed w-96 bg-black text-white [z-index:100] max-sm:hidden"
    :class="{
      '!top-1/2':
        isPosition(notificationPosition.LEFT) ||
        isPosition(notificationPosition.RIGHT),
      'right-0': isPosition(notificationPosition.RIGHT),
      'left-1/2':
        isPosition(notificationPosition.TOP) ||
        isPosition(notificationPosition.BOTTOM),
      'bottom-0': isPosition(notificationPosition.BOTTOM),
    }"
    :style="{ transform: getTransform() }"
  >
    <div class="ml-auto w-fit">
      <Icon
        class="m-2 cursor-pointer"
        name="ic:baseline-close"
        size="30"
        @click="close"
      />
    </div>
    <div class="flex flex-nowrap">
      <h3
        class="font-pragmatica-ext m-3 mb-4 rotate-180 transform text-2xl font-bold [writing-mode:vertical-rl]"
      >
        {{ notification.title }}
      </h3>
      <div class="ml-4 mr-8 mt-2 flex w-full flex-col justify-between">
        <div class="text-sm" v-html="notification.content" />
        <div class="mb-8 ml-auto">
          <NuxtLink
            class="no-underline"
            :to="notification.buttonLink"
            @click="close"
          >
            <div
              class="whitespace-nowrap border-2 border-white px-5 py-2 text-xl font-bold hover:bg-white hover:text-black"
            >
              {{ notification.buttonText }}
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
