import type { FetchOptions, ResponseType } from 'ofetch'
import { defineStore } from 'pinia'
import type { AccessToken, ApplicationUser } from '~/types/api'
import * as Sentry from '@sentry/vue'

export const useUser = defineStore('user', () => {
  const { baseUrl } = useRuntimeConfig().public

  const user = ref<ApplicationUser | null>(null)
  // cookie changes take time to propagate, which can cause issues during log out
  const userRefreshToken = ref<string | undefined>(undefined)

  const refreshPromise = ref<Promise<Ref<AccessToken>> | null>(null)
  const refreshRunning = ref(false)

  watch(user, (newUser) => {
    if (newUser) {
      Sentry.setUser({
        id: newUser.uid,
        name: newUser.firstName + ' ' + newUser.lastName,
        email: newUser.email,
      })
    } else {
      Sentry.setUser(null)
    }
  })

  const cookieOptions = {
    // Get domain only.
    domain: baseUrl
      .replace('https://', '')
      .replace('http://', '')
      .replace(':3000', ''),
    path: '/',
  }
  const accessToken = useCookie<string | undefined>(
    'accessToken',
    cookieOptions
  )
  const refreshToken = useCookie<string | undefined>(
    'refreshToken',
    cookieOptions
  )
  const accessTokenExpiresAt = useCookie<number | undefined>(
    'accessTokenExpiresAt',
    cookieOptions
  )
  userRefreshToken.value = refreshToken.value

  const isLoggedIn = computed(() => {
    return accessToken.value?.length && user.value !== null
  })
  function isAccessExpired() {
    if (!accessTokenExpiresAt.value) return true

    return Date.now() + 5000 >= accessTokenExpiresAt.value
  }
  const userCustomerGroupId = computed(() => {
    return user.value?.customerGroup?.nid
  })
  const userGroupId = computed(() => {
    return user.value?.customerGroup?.parentGroup
  })
  const showPrices = computed(() => {
    return !!isLoggedIn.value && !!userCustomerGroupId.value
  })

  async function initUser() {
    const { data: userData, error } = await useAsyncData(() =>
      createAuthRepository().introspect()
    )

    if (userData.value) {
      user.value = userData.value
    } else if (error.value) {
      throw error.value
    }
  }

  async function initRefresh() {
    if (!refreshToken.value) {
      return
    }

    const nuxtApp = useNuxtApp()

    const { data: accessTokenData, error } = await useAsyncData(() =>
      createAuthRepository().refresh({
        refreshToken: refreshToken.value!,
      })
    )

    if (accessTokenData.value) {
      accessTokenExpiresAt.value =
        accessTokenData.value.expiresIn * 1000 + new Date().getTime()
      accessToken.value = accessTokenData.value.accessToken
      refreshToken.value = accessTokenData.value.refreshToken
      userRefreshToken.value = accessTokenData.value.refreshToken
    } else if (error.value) {
      logout()
    }
  }

  async function setUser(accessData: AccessToken | undefined) {
    if (!accessData) {
      return
    }
    accessTokenExpiresAt.value =
      accessData.expiresIn * 1000 + new Date().getTime()

    accessToken.value = accessData.accessToken
    refreshToken.value = accessData.refreshToken
    userRefreshToken.value = accessData.refreshToken

    // wait for cookie changes to propagate
    await nextTick(async () => {
      const userData = await createAuthRepository().introspect()
      user.value = userData
    })
  }

  async function refresh(url: string, options: FetchOptions<ResponseType>) {
    if (refreshRunning.value && !url.includes('/auth/')) {
      if (refreshPromise.value === null) {
        return options
      }
      // wait for running refresh request
      const result = await refreshPromise.value
      if (result?.value?.accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${result?.value?.accessToken}`,
        }
      }
    } else if (
      !refreshRunning.value &&
      userRefreshToken.value?.length &&
      isAccessExpired() &&
      refreshToken.value !== undefined &&
      refreshToken.value !== null
    ) {
      // perform refresh request and save it
      refreshRunning.value = true
      refreshPromise.value = createAuthRepository()
        .refresh({
          refreshToken: refreshToken.value,
        })
        .then((result) => {
          setUser(result).catch(() => {
            // fail silently
          })
          refreshPromise.value = null
          refreshRunning.value = false
          return result
        })
        .catch((e) => {
          // fail silently otherwise this might cause errors for API calls which do not need authorization
          refreshRunning.value = false
          clearUserCookies()
        })

      const result = await refreshPromise.value
      if (result?.value?.accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${result?.value?.accessToken}`,
        }
      }
    }

    return options
  }

  function logout() {
    user.value = null
    accessTokenExpiresAt.value = 0

    clearUserCookies()
    userRefreshToken.value = undefined
  }

  function clearUserCookies() {
    accessToken.value = undefined
    refreshToken.value = undefined
  }

  return {
    user,
    userRefreshToken,
    accessToken,
    accessTokenExpiresAt,
    isLoggedIn,
    isAccessExpired,
    userCustomerGroupId,
    userGroupId,
    showPrices,
    setUser,
    logout,
    clearUserCookies,
    refresh,
    initUser,
    initRefresh,
  }
})
