export default function useResources() {
  const {
    public: { resourcesUrl },
  } = useRuntimeConfig()

  function getResourceUrl(resource: string | undefined): string {
    if (resource === undefined) {
      return ''
    }

    return resourcesUrl + resource
  }

  function getSrcSetUrl(sources: string[]): string {
    return sources.map((src) => resourcesUrl + src).join(', ')
  }

  // prettier-ignore
  function getImageSrcSet(image: string | undefined): string {
    if (image === undefined) {
      return ''
    }

    return resourcesUrl + image + '/86x86/FIT 86w, ' +
      resourcesUrl + image + '/100x100/FIT 100w, ' +
      resourcesUrl + image + '/300x300/FIT 300w, ' +
      resourcesUrl + image + '/576x576/FIT 576w, ' +
      resourcesUrl + image + '/992x992/FIT 992w, ' +
      resourcesUrl + image + '/1150x1150/FIT 1150w, ' +
      resourcesUrl + image + '/1280x1280/FIT 1280w, ' +
      resourcesUrl + image + '/1440x1440/FIT 1440w, ' +
      resourcesUrl + image + '/1600x1600/FIT 1600w, ' +
      resourcesUrl + image + '/1800x1800/FIT 1800w, ' +
      resourcesUrl + image + '/1920x1920/FIT 1920w, ' +
      resourcesUrl + image + '/2200x2200/FIT 2200w, ' +
      resourcesUrl + image + '/2400x2400/FIT 2400w, ' +
      resourcesUrl + image + '/2560x2560/FIT 2560w'
  }

  return {
    getResourceUrl,
    getSrcSetUrl,
    getImageSrcSet,
  }
}
