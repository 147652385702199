import revive_payload_client_QsXnmtlqgI from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ci1JhY3UEF from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CzDFzr73Oc from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_WPCeGKtsze from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-site-config@2.2.14_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@18.19.39_sa_dnysaakarggesogcwh2b5vbwoq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_iOtxQzVbHO from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2dGSekmtNA from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LMQRrzqJ3e from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_JDMvx4pcLk from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OgIGSd8tJk from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.31_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/ldseating/ldseating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_zJuESqxUrT from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_eslint@8.57.0_idb-keyval@6.2.1_ioredis_vdrcsdroljg5iu7n62ugojxcma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eoKHDdHJ7Y from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.5.3_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_client_y1up8mdQDJ from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import titles_QvIR3yPVg1 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.4_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@18.19.39_sas_wr4ppzn2vezfyx6dudw6qlawte/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_UZUP66noCH from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.4_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@18.19.39_sas_wr4ppzn2vezfyx6dudw6qlawte/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_7E6ZiXvVQl from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.10_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@18_4ybavpionfnhhjf6zrdr6plqha/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_oQxbnbKSDC from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.10_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@18_4ybavpionfnhhjf6zrdr6plqha/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_UCP9SuRr5o from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_idb-keyval@6.2.1_magicast@0.3.4_qrcode@1.5.4_rollup@4.18.1_v_gxsl6ewsuqhofftqn3jnilt3ae/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_QLRfW7NyEI from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_idb-keyval@6.2.1_magicast@0.3.4_qrcode@1.5.4_rollup@4.18.1_v_gxsl6ewsuqhofftqn3jnilt3ae/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_Xg8j3NXf4l from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_idb-keyval@6.2.1_magicast@0.3.4_qrcode@1.5.4_rollup@4.18.1_v_gxsl6ewsuqhofftqn3jnilt3ae/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_ZJfltoGOVd from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import i18n_jaB8o6MZYI from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_0nr23fGoRQ from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_q8aEDQTQEd from "/builds/ldseating/ldseating-frontend/modules/cookies/runtime/plugin.ts";
import plugin_8GehmYUrTH from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typesc_d4fudqhx4dgfwk7zophzbdglia/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/builds/ldseating/ldseating-frontend/plugins/api.ts";
import gtag_client_Zw8EQXNVTz from "/builds/ldseating/ldseating-frontend/plugins/gtag.client.ts";
import sentry_client_shVUlIjFLk from "/builds/ldseating/ldseating-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_QsXnmtlqgI,
  unhead_Ci1JhY3UEF,
  router_CzDFzr73Oc,
  _0_siteConfig_WPCeGKtsze,
  payload_client_iOtxQzVbHO,
  navigation_repaint_client_2dGSekmtNA,
  check_outdated_build_client_LMQRrzqJ3e,
  chunk_reload_client_JDMvx4pcLk,
  plugin_vue3_OgIGSd8tJk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zJuESqxUrT,
  plugin_eoKHDdHJ7Y,
  plugin_client_y1up8mdQDJ,
  titles_QvIR3yPVg1,
  defaults_UZUP66noCH,
  siteConfig_7E6ZiXvVQl,
  inferSeoMetaPlugin_oQxbnbKSDC,
  slideovers_UCP9SuRr5o,
  modals_QLRfW7NyEI,
  colors_Xg8j3NXf4l,
  plugin_client_ZJfltoGOVd,
  i18n_jaB8o6MZYI,
  nuxt_plugin_0nr23fGoRQ,
  plugin_q8aEDQTQEd,
  plugin_8GehmYUrTH,
  api_GFfDXud5Cr,
  gtag_client_Zw8EQXNVTz,
  sentry_client_shVUlIjFLk
]