<script setup lang="ts">
import type { BreadcrumbItemProps } from '@nuxtjs/seo/dist/runtime/nuxt/composables/useBreadcrumbItems'

const props = defineProps<{
  title: string
  description?: string
  slug?: string
  overrides?: (BreadcrumbItemProps | false | undefined)[]
}>()
</script>

<template>
  <div
    class="container flex flex-col flex-wrap justify-between gap-x-16 py-5 sm:pb-10 md:flex-row md:items-center xl:py-16"
  >
    <div
      class="flex gap-5 sm:gap-10"
      :class="{
        'flex-col-reverse': props.description && props.description.length,
        'w-full flex-wrap items-center justify-between':
          !props.description || !props.description.length,
      }"
    >
      <UiBreadcrumbs rounded :slug="props.slug" :overrides="props.overrides" />
      <UiSkeletonText
        class="my-14 h-24 max-w-md lg:my-10 lg:h-36 lg:max-w-4xl"
        v-if="!props.title || !props.title.length"
      />
      <h1
        v-else
        class="font-pragmatica-ext text-4xl font-bold sm:text-[calc(3.25rem+.5vw)] lg:text-7xl 2xl:text-8xl"
        v-html="props.title"
      ></h1>
    </div>
    <p
      v-if="props.description"
      class="font-pragmatica-ext mt-4 max-w-2xl text-pretty py-5 text-xl font-bold"
    >
      {{ props.description }}
    </p>
  </div>
</template>
