export default defineNuxtRouteMiddleware(async (to, from) => {
  const { user, accessToken, isAccessExpired, initUser, initRefresh, logout } =
    useUser()

  if (!user && accessToken) {
    try {
      if (isAccessExpired()) {
        await initRefresh()
      }
      await initUser()
    } catch (e) {
      // TODO: Refresh token or log out
      console.error('Error while fetching user data', e)
      logout()
    }
  }
})
