import type {
  AccessToken,
  ApplicationUser,
  ConfirmEmailRequest,
  ForgotPasswordRequest,
  LoginRequest,
  RefreshRequest,
  RegisterRequest,
  ResendConfirmationEmailRequest,
  ResetPasswordRequest,
  UpdateUserRequest,
} from '~/types/api'

export function createAuthRepository() {
  const { $api } = useNuxtApp()
  const { get, post } = baseRepository($api)

  const DOMAIN = 'auth'

  async function register(body: RegisterRequest) {
    // In original code, there is no return statement here
    // I think we should return the result of the post request
    // Also it should be typed (not as undefined)
    return post<undefined>([DOMAIN, 'register'], { body })
  }
  async function confirmEmail(body: ConfirmEmailRequest) {
    return post<undefined>([DOMAIN, 'confirm-email'], { body })
  }
  async function resendConfirmationEmail(body: ResendConfirmationEmailRequest) {
    return post<undefined>([DOMAIN, 'resend-confirmation-email'], { body })
  }
  async function login(body: LoginRequest) {
    return post<AccessToken>([DOMAIN, 'login'], { body })
  }
  async function refresh(body: RefreshRequest) {
    return post<AccessToken>([DOMAIN, 'refresh-token'], {
      body,
    })
  }
  async function introspect() {
    return get<ApplicationUser>([DOMAIN, 'introspect'])
  }
  async function update(body: UpdateUserRequest) {
    return post<undefined>([DOMAIN, 'update'], {
      body,
    })
  }
  async function forgotPassword(body: ForgotPasswordRequest) {
    return post<undefined>([DOMAIN, 'forgot-password'], { body })
  }
  async function resetPassword(body: ResetPasswordRequest) {
    return post<undefined>([DOMAIN, 'reset-password'], { body })
  }

  return {
    register,
    confirmEmail,
    resendConfirmationEmail,
    login,
    refresh,
    introspect,
    update,
    forgotPassword,
    resetPassword,
  }
}
