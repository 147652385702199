type ObjectValues<T> = T[keyof T]

export enum Locales {
  EU_en = 'EU_en',
  CZ_cs = 'CZ_cs',
  DE_de = 'DE_de',
  FR_fr = 'FR_fr',
}

export default Locales

export const ApiLocale = {
  [Locales.EU_en]: 'EU_en',
  [Locales.CZ_cs]: 'CZ_cs',
  [Locales.DE_de]: 'DE_de',
  [Locales.FR_fr]: 'FR_fr',
} as const

export type ApiLocale = ObjectValues<typeof ApiLocale>

export const NuxtLocale = {
  [Locales.EU_en]: 'en',
  [Locales.CZ_cs]: 'cs',
  [Locales.DE_de]: 'de',
  [Locales.FR_fr]: 'fr',
} as const

export type NuxtLocale = ObjectValues<typeof NuxtLocale>
