<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUser } from '@/stores/user'
import { userGroups } from '~/constants/userGroups'

const { t } = useI18n()
const { getStaticPageSlug } = useStaticPages()

const userStore = useUser()

const { user, isLoggedIn, userGroupId } = storeToRefs(userStore)

const localePath = useLocalePath()
const { locale } = useI18n()

const productsLink = {
  name: 'products',
  slug: undefined,
  labelPath: 'pages.products.label',
}
const links = computed(() => [
  { name: 'lookbook', slug: undefined, labelPath: 'pages.lookbook.label' },
  { name: 'references', slug: undefined, labelPath: 'pages.references.label' },
  { name: 'designers', slug: undefined, labelPath: 'pages.designers.label' },
  { name: 'download', slug: undefined, labelPath: 'pages.toDownload.label' },
  { name: 'about', slug: undefined, labelPath: 'pages.about.label' },
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.CONTACT),
    labelPath: 'pages.contact.label',
  },
])

const changingLang = ref(false)
const userDetailOpen = ref(false)
const priceSettingsOpen = ref(false)
const isOpen = ref(false)

const alternativeMenu = computed(() => {
  return changingLang.value || userDetailOpen.value || priceSettingsOpen.value
})

function toggleAlternativeMenu(currentValue: boolean, callback: Function) {
  changingLang.value = false
  userDetailOpen.value = false
  priceSettingsOpen.value = false

  if (!currentValue) {
    document.addEventListener('click', closeAlternativeMenu)
  }
  callback(currentValue)
}

function closeAlternativeMenu(event: Event) {
  if (!event.target?.closest('.alternative-menu')) {
    changingLang.value = false
    userDetailOpen.value = false
    priceSettingsOpen.value = false

    document.removeEventListener('click', closeAlternativeMenu)
  }
}

watch(isLoggedIn, (value) => {
  if (!value && userDetailOpen.value) {
    // hide logout
    toggleUserDetail()
  }
})

function toggleUserDetail() {
  toggleAlternativeMenu(
    userDetailOpen.value,
    (value: boolean) => (userDetailOpen.value = !value)
  )
}

function togglePriceSettings() {
  toggleAlternativeMenu(
    priceSettingsOpen.value,
    (value: boolean) => (priceSettingsOpen.value = !value)
  )
}

function toggleLangSelector() {
  toggleAlternativeMenu(
    changingLang.value,
    (value: boolean) => (changingLang.value = !value)
  )
}

const { currentRoute } = useRouter()
watch(
  () => currentRoute.value.fullPath,
  () => {
    isOpen.value = false
  }
)

onMounted(() => {
  window.onresize = checkMobileMenu
})

function checkMobileMenu() {
  if (isOpen.value && window.innerWidth > 1024) {
    isOpen.value = false
  }
}

const searching = ref(false)
</script>

<template>
  <header
    :class="isOpen ? 'flex min-h-screen flex-col justify-between' : 'h-32'"
    class="container"
    role="banner"
  >
    <div
      class="flex h-full max-w-full"
      :class="
        isOpen
          ? '!h-max flex-col items-start'
          : 'flex-row items-center justify-between'
      "
    >
      <div
        class="flex h-32 w-full items-center justify-between"
        :class="{ 'lg:w-max': searching }"
      >
        <NuxtLink aria-label="Homepage link" :to="localePath('/')">
          <UiLDSLogo class="h-20" />
        </NuxtLink>
        <div class="flex gap-2 xl:gap-4">
          <UiHeaderFulltextSearchBtn
            v-if="isOpen"
            phone
            class="!text-xl"
            v-model="searching"
          />
          <button class="lg:hidden" aria-label="Menu button">
            <Icon
              v-if="!isOpen"
              class="cursor-pointer"
              name="iconamoon:menu-burger-horizontal-fill"
              size="40"
              @click="isOpen = true"
            />
            <Icon
              v-else-if="!searching"
              class="cursor-pointer"
              name="ic:baseline-close"
              size="40"
              @click="isOpen = false"
            />
          </button>
        </div>
      </div>
      <div v-if="searching" class="hidden w-full px-20 lg:block">
        <UiHeaderFulltextSearch @close="searching = false" />
      </div>
      <div
        class="w-full flex-col gap-4 lg:flex"
        :class="[
          { hidden: !isOpen },
          { '!items-start lg:h-[85px]': searching },
        ]"
      >
        <div
          v-if="!isOpen"
          class="alternative-menu ml-auto flex items-baseline gap-6 whitespace-nowrap"
        >
          <UiHeaderFulltextSearchBtn v-model="searching" />
          <LazyLoginButton v-show="!isLoggedIn" />
          <template v-if="isLoggedIn">
            <UiHeaderExpandableButton
              :label="(user?.firstName ?? '') + ' ' + (user?.lastName ?? '')"
              :active="userDetailOpen"
              @toggle="toggleUserDetail"
            />
            <UiHeaderExpandableButton
              v-if="userGroupId === userGroups.ADMIN"
              :label="t('base.header.priceSettings.title')"
              :active="priceSettingsOpen"
              @toggle="togglePriceSettings"
            />
          </template>
          <UiHeaderExpandableButton
            :label="locale"
            :active="changingLang"
            uppercase
            @toggle="toggleLangSelector"
          />
        </div>
        <div v-if="!isOpen" class="h-full"></div>
        <div
          v-if="!searching"
          class="alternative-menu flex flex-col items-baseline justify-start gap-3 lg:flex-row lg:justify-end xl:gap-6 xl:text-lg"
          :class="{ 'text-xl': isOpen }"
        >
          <template v-if="alternativeMenu">
            <LazyUiHeaderUserMenu v-if="userDetailOpen" />
            <LazyUiHeaderPriceSettings v-if="priceSettingsOpen" />
            <LazyUiHeaderLangSelector v-if="changingLang" />
          </template>
          <template v-else>
            <LazyUiProductLinesDropdown />
            <LazyUiHeaderLinkButton
              v-for="(link, index) in links"
              :key="index"
              :link="link"
            />
          </template>
        </div>
      </div>
      <div
        v-if="searching && isOpen"
        class="min-h-full w-full md:px-10 lg:hidden"
      >
        <UiHeaderFulltextSearch @close="searching = false" />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="alternative-menu mx-5 flex justify-between border-t border-black p-3"
    >
      <LazyLoginButton v-show="!isLoggedIn" />
      <template v-if="isLoggedIn">
        <UiHeaderExpandableButton
          :label="(user?.firstName ?? '') + ' ' + (user?.lastName ?? '')"
          :active="userDetailOpen"
          @toggle="toggleUserDetail"
        />
        <UiHeaderExpandableButton
          v-if="userGroupId === userGroups.ADMIN"
          :label="t('base.header.priceSettings.title')"
          :active="priceSettingsOpen"
          @toggle="togglePriceSettings"
        />
      </template>
      <UiHeaderExpandableButton
        :label="locale"
        :active="changingLang"
        uppercase
        @toggle="toggleLangSelector"
      />
    </div>
  </header>
</template>
