<script lang="ts" setup>
const props = defineProps<{
  class?: string
}>()
</script>

<template>
  <div
    :class="
      cn('h-6 w-full max-w-full animate-pulse bg-neutral-200', props.class)
    "
  ></div>
</template>
