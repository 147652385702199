<script setup lang="ts">
import type { Notification } from '~/types/api'
import { useNotificationsStore } from '~/stores/notifications'

const props = defineProps<{ notification: Notification }>()

const { getResourceUrl } = useResources()

const isOpen = ref(true)

watch(isOpen, () => close())

const notificationsStore = useNotificationsStore()
const { closeNotification } = notificationsStore

function close() {
  isOpen.value = false
  document
    .querySelector('body')
    ?.classList.remove('overflow-hidden', 'h-screen')
  closeNotification(props.notification)
}

onMounted(() => {
  document.querySelector('body')?.classList.add('overflow-hidden', 'h-screen')
})
</script>

<template>
  <section>
    <div
      class="fixed z-50 grid h-full w-full place-items-center overflow-auto bg-white bg-opacity-75 px-10 backdrop-blur-md cursor-pointer"
      v-if="isOpen"
      @click="close"
    >
      <UiDualPaneScrollShift
        class="relative mx-auto my-10 grid grid-cols-1 sm:max-w-xl md:max-w-screen-sm lg:max-w-screen-lg lg:grid-cols-2 cursor-default"
        :amount="10"
        on-hover
        reset
        @click.stop
      >
        <template #left>
          <div>
            <div
              class="mb-3 ml-auto h-6 w-fit cursor-pointer border-b border-black bg-transparent"
            >
              <span class="sm:!hidden" @click="close">
                {{ $t('base.close') }}
              </span>
            </div>
            <div
              class="flex flex-col justify-between bg-black p-8 text-white sm:h-full sm:p-12"
            >
              <h3 class="font-pragmatica-ext mb-8 text-2xl font-bold sm:mb-12">
                {{ notification.title }}
              </h3>
              <div class="mb-10 text-sm" v-html="notification.content" />
              <div class="mr-auto">
                <NuxtLink
                  class="no-underline"
                  :to="notification.buttonLink"
                  @click="close"
                >
                  <div
                    class="whitespace-nowrap border-2 border-white bg-white px-5 py-2 text-xl font-bold text-black hover:bg-black hover:text-white"
                  >
                    {{ notification.buttonText }}
                  </div>
                </NuxtLink>
              </div>
            </div>
          </div>
        </template>
        <template #right="{ animationClass }">
          <div class="sm:h-full" :class="animationClass">
            <div
              class="mb-3 ml-auto h-6 w-fit cursor-pointer border-b border-black bg-transparent max-sm:hidden"
              @click="close"
            >
              {{ $t('base.close') }}
            </div>
            <NuxtImg
              v-if="notification.image"
              :src="getResourceUrl(notification?.image?.url)"
              sizes="500px"
              class="h-full w-full border-0 object-cover object-bottom"
              alt=""
              title=""
              loading="lazy"
            />
          </div>
        </template>
      </UiDualPaneScrollShift>
    </div>
  </section>
</template>

<style scoped></style>
