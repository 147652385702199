<script setup lang="ts">
import type { ProductLineSearchPagedResult } from '~/types/api'

const props = defineProps<{
  productLines: ProductLineSearchPagedResult
  short?: boolean
}>()

const items = computed(() =>
  props.short ? props.productLines.items.slice(0, 6) : props.productLines.items
)

const emit = defineEmits(['linkClicked'])
</script>

<template>
  <div class="pb-10 xl:pb-20">
    <h2 class="font-pragmatica-ext mb-8 text-2xl font-bold">
      {{ $t('base.search.productLines') }}
    </h2>
    <div class="w-full overflow-hidden">
      <div
        class="-mr-[4px] mb-16 mt-5 grid h-fit grid-cols-1 overflow-hidden border-t-[4px] border-white md:grid-cols-3 lg:grid-cols-4"
      >
        <div v-for="line in items" :key="line.nid">
          <ProductLinesProductLineCard
            @click="emit('linkClicked')"
            :product-line="line"
            dense
            class="border-b-[4px] border-r-[4px] border-white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
