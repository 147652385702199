<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
  uppercase: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['toggle'])

const arrowDown = 'arrow-down'
const arrowUp = 'arrow-up'
const arrowIcon = ref(arrowDown)
const activeLocal = ref(false)

watch(
  () => props.active,
  (value) => {
    if (activeLocal.value !== value) {
      update()
    }
  }
)

function update() {
  if (arrowIcon.value === arrowDown) {
    arrowIcon.value = arrowUp
    activeLocal.value = true
  } else {
    arrowIcon.value = arrowDown
    activeLocal.value = false
  }
}

function handleClick() {
  update()
  emit('toggle')
}
</script>

<template>
  <button
    class="font-weight-normal cursor-pointer"
    :class="{ uppercase: uppercase, 'opacity-50': active }"
    @click="handleClick"
  >
    {{ label }}
    <Icon
      class="text-neutral-400"
      :name="'fa6-solid:' + arrowIcon"
      size="0.75em"
    />
  </button>
</template>

<style scoped></style>