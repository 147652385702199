<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNotificationsStore } from '~/stores/notifications'
import { useUser } from '~/stores/user'

const notificationsStore = useNotificationsStore()
const userStore = useUser()

const { registerNotification } = storeToRefs(notificationsStore)
const { user } = storeToRefs(userStore)

const { t, availableLocales, locale } = useI18n()

const GeneralEmails = [
  'cenik@ldseating.com',
  'cenysk@ldseating.com',
  'preisliste@ldseating.com',
  'ldpricelist@ldseating.com',
  'frlistedeprix@ldseating.com',
  'atpreisliste@ldseating.com',
  'nlpricelist@ldseating.com',
  'chpreisliste@ldseating.com',
  'united@ldseating.com',
  'island@ldseating.com',
  'portland@ldseating.com',
]

watch(
  user,
  () => {
    if (user && GeneralEmails.includes(user.value?.email)) {
      notificationsStore.showRegisterNotification(true)
    }
  },
  { immediate: true }
)

function goToSignupForm(): void {
  notificationsStore.showRegisterNotification(false)
  const fragment = 'registration'
  const rootPathNames = availableLocales.map((loc) => `/${loc}`)
  if (rootPathNames.includes(location.pathname)) {
    setTimeout(() => {
      document.getElementById(fragment)?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  } else {
    window.location.href = `/${locale.value}#${fragment}`
  }
}
</script>

<template>
  <UiModal
    :model-value="registerNotification"
    title-path="base.login.registrationRequired.title"
    closeable
    @update:model-value="notificationsStore.showRegisterNotification"
  >
    <div class="whitespace-normal pb-4 text-lg font-semibold">
      {{ t('base.login.registrationRequired.message', [user?.email]) }}
    </div>
    <div class="px-8 pt-8">
      <button
        class="w-full border-2 border-black px-8 py-4 text-xl font-bold"
        @click="goToSignupForm"
      >
        {{ t('base.login.registrationRequired.action') }}
      </button>
    </div>
  </UiModal>
</template>

<style scoped></style>
