
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en",
  "de",
  "fr"
]

export const localeLoaders = {
  "cs": [{ key: "../lang/translations/baseCommon.ts", load: () => import("../lang/translations/baseCommon.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_baseCommon_ts" */), cache: true },
{ key: "../lang/translations/base.cs.ts", load: () => import("../lang/translations/base.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_base_cs_ts" */), cache: true },
{ key: "../lang/translations/pages.cs.ts", load: () => import("../lang/translations/pages.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_pages_cs_ts" */), cache: true },
{ key: "../lang/translations/signup.cs.ts", load: () => import("../lang/translations/signup.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_signup_cs_ts" */), cache: true },
{ key: "../lang/translations/user.cs.ts", load: () => import("../lang/translations/user.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_user_cs_ts" */), cache: true },
{ key: "../lang/translations/aboutCompany.cs.ts", load: () => import("../lang/translations/aboutCompany.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_aboutCompany_cs_ts" */), cache: true },
{ key: "../lang/translations/forCustomers.cs.ts", load: () => import("../lang/translations/forCustomers.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_forCustomers_cs_ts" */), cache: true },
{ key: "../lang/translations/emails.cs.ts", load: () => import("../lang/translations/emails.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_emails_cs_ts" */), cache: true },
{ key: "../lang/translations/countries.cs.ts", load: () => import("../lang/translations/countries.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_countries_cs_ts" */), cache: true },
{ key: "../lang/translations/breadcrumb.cs.ts", load: () => import("../lang/translations/breadcrumb.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_breadcrumb_cs_ts" */), cache: true },
{ key: "../lang/translations/error.cs.ts", load: () => import("../lang/translations/error.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_error_cs_ts" */), cache: true },
{ key: "../lang/translations/configurator.cs.ts", load: () => import("../lang/translations/configurator.cs.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_configurator_cs_ts" */), cache: true }],
  "en": [{ key: "../lang/translations/baseCommon.ts", load: () => import("../lang/translations/baseCommon.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_baseCommon_ts" */), cache: true },
{ key: "../lang/translations/base.en.ts", load: () => import("../lang/translations/base.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_base_en_ts" */), cache: true },
{ key: "../lang/translations/pages.en.ts", load: () => import("../lang/translations/pages.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_pages_en_ts" */), cache: true },
{ key: "../lang/translations/signup.en.ts", load: () => import("../lang/translations/signup.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_signup_en_ts" */), cache: true },
{ key: "../lang/translations/user.en.ts", load: () => import("../lang/translations/user.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_user_en_ts" */), cache: true },
{ key: "../lang/translations/aboutCompany.en.ts", load: () => import("../lang/translations/aboutCompany.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_aboutCompany_en_ts" */), cache: true },
{ key: "../lang/translations/forCustomers.en.ts", load: () => import("../lang/translations/forCustomers.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_forCustomers_en_ts" */), cache: true },
{ key: "../lang/translations/emails.en.ts", load: () => import("../lang/translations/emails.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_emails_en_ts" */), cache: true },
{ key: "../lang/translations/countries.en.ts", load: () => import("../lang/translations/countries.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_countries_en_ts" */), cache: true },
{ key: "../lang/translations/breadcrumb.en.ts", load: () => import("../lang/translations/breadcrumb.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_breadcrumb_en_ts" */), cache: true },
{ key: "../lang/translations/error.en.ts", load: () => import("../lang/translations/error.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_error_en_ts" */), cache: true },
{ key: "../lang/translations/configurator.en.ts", load: () => import("../lang/translations/configurator.en.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_configurator_en_ts" */), cache: true }],
  "de": [{ key: "../lang/translations/baseCommon.ts", load: () => import("../lang/translations/baseCommon.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_baseCommon_ts" */), cache: true },
{ key: "../lang/translations/base.de.ts", load: () => import("../lang/translations/base.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_base_de_ts" */), cache: true },
{ key: "../lang/translations/pages.de.ts", load: () => import("../lang/translations/pages.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_pages_de_ts" */), cache: true },
{ key: "../lang/translations/signup.de.ts", load: () => import("../lang/translations/signup.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_signup_de_ts" */), cache: true },
{ key: "../lang/translations/user.de.ts", load: () => import("../lang/translations/user.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_user_de_ts" */), cache: true },
{ key: "../lang/translations/aboutCompany.de.ts", load: () => import("../lang/translations/aboutCompany.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_aboutCompany_de_ts" */), cache: true },
{ key: "../lang/translations/forCustomers.de.ts", load: () => import("../lang/translations/forCustomers.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_forCustomers_de_ts" */), cache: true },
{ key: "../lang/translations/emails.de.ts", load: () => import("../lang/translations/emails.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_emails_de_ts" */), cache: true },
{ key: "../lang/translations/countries.de.ts", load: () => import("../lang/translations/countries.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_countries_de_ts" */), cache: true },
{ key: "../lang/translations/breadcrumb.de.ts", load: () => import("../lang/translations/breadcrumb.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_breadcrumb_de_ts" */), cache: true },
{ key: "../lang/translations/error.de.ts", load: () => import("../lang/translations/error.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_error_de_ts" */), cache: true },
{ key: "../lang/translations/configurator.de.ts", load: () => import("../lang/translations/configurator.de.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_configurator_de_ts" */), cache: true }],
  "fr": [{ key: "../lang/translations/baseCommon.ts", load: () => import("../lang/translations/baseCommon.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_baseCommon_ts" */), cache: true },
{ key: "../lang/translations/base.fr.ts", load: () => import("../lang/translations/base.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_base_fr_ts" */), cache: true },
{ key: "../lang/translations/pages.fr.ts", load: () => import("../lang/translations/pages.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_pages_fr_ts" */), cache: true },
{ key: "../lang/translations/signup.fr.ts", load: () => import("../lang/translations/signup.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_signup_fr_ts" */), cache: true },
{ key: "../lang/translations/user.fr.ts", load: () => import("../lang/translations/user.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_user_fr_ts" */), cache: true },
{ key: "../lang/translations/aboutCompany.fr.ts", load: () => import("../lang/translations/aboutCompany.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_aboutCompany_fr_ts" */), cache: true },
{ key: "../lang/translations/forCustomers.fr.ts", load: () => import("../lang/translations/forCustomers.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_forCustomers_fr_ts" */), cache: true },
{ key: "../lang/translations/emails.fr.ts", load: () => import("../lang/translations/emails.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_emails_fr_ts" */), cache: true },
{ key: "../lang/translations/countries.fr.ts", load: () => import("../lang/translations/countries.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_countries_fr_ts" */), cache: true },
{ key: "../lang/translations/breadcrumb.fr.ts", load: () => import("../lang/translations/breadcrumb.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_breadcrumb_fr_ts" */), cache: true },
{ key: "../lang/translations/error.fr.ts", load: () => import("../lang/translations/error.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_error_fr_ts" */), cache: true },
{ key: "../lang/translations/configurator.fr.ts", load: () => import("../lang/translations/configurator.fr.ts" /* webpackChunkName: "locale__builds_ldseating_ldseating_frontend_lang_translations_configurator_fr_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../lang/i18n.config.ts?hash=980b4b1d&config=1" /* webpackChunkName: "__lang_i18n_config_ts_980b4b1d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./lang/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./lang/i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "name": "Čeština",
      "files": [
        "lang/translations/baseCommon.ts",
        "lang/translations/base.cs.ts",
        "lang/translations/pages.cs.ts",
        "lang/translations/signup.cs.ts",
        "lang/translations/user.cs.ts",
        "lang/translations/aboutCompany.cs.ts",
        "lang/translations/forCustomers.cs.ts",
        "lang/translations/emails.cs.ts",
        "lang/translations/countries.cs.ts",
        "lang/translations/breadcrumb.cs.ts",
        "lang/translations/error.cs.ts",
        "lang/translations/configurator.cs.ts"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "lang/translations/baseCommon.ts",
        "lang/translations/base.en.ts",
        "lang/translations/pages.en.ts",
        "lang/translations/signup.en.ts",
        "lang/translations/user.en.ts",
        "lang/translations/aboutCompany.en.ts",
        "lang/translations/forCustomers.en.ts",
        "lang/translations/emails.en.ts",
        "lang/translations/countries.en.ts",
        "lang/translations/breadcrumb.en.ts",
        "lang/translations/error.en.ts",
        "lang/translations/configurator.en.ts"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "lang/translations/baseCommon.ts",
        "lang/translations/base.de.ts",
        "lang/translations/pages.de.ts",
        "lang/translations/signup.de.ts",
        "lang/translations/user.de.ts",
        "lang/translations/aboutCompany.de.ts",
        "lang/translations/forCustomers.de.ts",
        "lang/translations/emails.de.ts",
        "lang/translations/countries.de.ts",
        "lang/translations/breadcrumb.de.ts",
        "lang/translations/error.de.ts",
        "lang/translations/configurator.de.ts"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "files": [
        "lang/translations/baseCommon.ts",
        "lang/translations/base.fr.ts",
        "lang/translations/pages.fr.ts",
        "lang/translations/signup.fr.ts",
        "lang/translations/user.fr.ts",
        "lang/translations/aboutCompany.fr.ts",
        "lang/translations/forCustomers.fr.ts",
        "lang/translations/emails.fr.ts",
        "lang/translations/countries.fr.ts",
        "lang/translations/breadcrumb.fr.ts",
        "lang/translations/error.fr.ts",
        "lang/translations/configurator.fr.ts"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./lang/translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "search": {
      "cs": "/vyhledavani",
      "en": "/search",
      "de": "/suche",
      "fr": "/recherche"
    },
    "products": {
      "cs": "/produkty",
      "en": "/products",
      "de": "/produkte",
      "fr": "/produits"
    },
    "products/[slug]": {
      "cs": "/produkty/[slug]",
      "en": "/products/[slug]",
      "de": "/produkte/[slug]",
      "fr": "/produits/[slug]"
    },
    "product-lines": {
      "cs": "/produktove-rady",
      "en": "/product-lines",
      "de": "/produktlinien",
      "fr": "/series-de-produits"
    },
    "product-lines/category/[slug]": {
      "cs": "/produktove-rady/kategorie/[slug]",
      "en": "/product-lines/category/[slug]",
      "de": "/produktlinien/kategorie/[slug]",
      "fr": "/series-de-produits/categorie/[slug]"
    },
    "product-lines/[slug]": {
      "cs": "/produktove-rady/[slug]",
      "en": "/product-lines/[slug]",
      "de": "/produktlinien/[slug]",
      "fr": "/series-de-produits/[slug]"
    },
    "designers": {
      "cs": "/designeri",
      "en": "/designers",
      "de": "/designer",
      "fr": "/createurs"
    },
    "designer/[slug]": {
      "cs": "/designer/[slug]",
      "en": "/designer/[slug]",
      "de": "/designer/[slug]",
      "fr": "/designer/[slug]"
    },
    "references/index": {
      "cs": "/reference",
      "en": "/references",
      "de": "/referenzen",
      "fr": "/references"
    },
    "references/categories/[nid]": {
      "cs": "/reference/kategorie/[nid]",
      "en": "/references/category/[nid]",
      "de": "/referenzen/kategorie/[nid]",
      "fr": "/references/categorie/[nid]"
    },
    "reference/[slug]": {
      "cs": "/reference/[slug]",
      "en": "/reference/[slug]",
      "de": "/reference/[slug]",
      "fr": "/reference/[slug]"
    },
    "lookbook": {
      "cs": "/lookbook",
      "en": "/lookbook",
      "de": "/lookbook",
      "fr": "/lookbook"
    },
    "download": {
      "cs": "/ke-stazeni",
      "en": "/downloads",
      "de": "/downloads",
      "fr": "/a-telecharger"
    },
    "about": {
      "cs": "/o-nas",
      "en": "/about-us",
      "de": "/ueber-uns",
      "fr": "/notre-societe"
    },
    "careers": {
      "cs": "/kariera",
      "en": "/careers",
      "de": "/karriere",
      "fr": "/carrière"
    },
    "press/index": {
      "cs": "/pro-media",
      "en": "/press",
      "de": "/presse",
      "fr": "/presse"
    },
    "press/[...slug]": {
      "cs": "/pro-media/[...slug]",
      "en": "/press/[...slug]",
      "de": "/presse/[...slug]",
      "fr": "/presse/[...slug]"
    },
    "email-verified": {
      "cs": "/email-overen",
      "en": "/email-verified",
      "de": "/email-uberpruft",
      "fr": "/email-verifie"
    },
    "news/index": {
      "cs": "/novinky",
      "en": "/news",
      "de": "/nachrichten",
      "fr": "/nouvelles"
    },
    "news/[slug]": {
      "cs": "/novinky/[slug]",
      "en": "/news/[slug]",
      "de": "/nachrichten/[slug]",
      "fr": "/nouvelles/[slug]"
    },
    "[slug]": {
      "cs": "/[slug]",
      "en": "/[slug]",
      "de": "/[slug]",
      "fr": "/[slug]"
    },
    "contact": {
      "cs": "/kontakt",
      "en": "/contact",
      "de": "/kontakt",
      "fr": "/contact"
    },
    "privacy-policy": {
      "cs": "/ochrana-osobnich-udaju",
      "en": "/privacy-policy",
      "de": "/datenschutzbereich",
      "fr": "/politique-de-confidentialite"
    },
    "sales-conditions": {
      "cs": "/obchodni-podminky",
      "en": "/sales-conditions",
      "de": "/geschaeftsbedingungen",
      "fr": "/termes-et-conditions"
    },
    "customer-service": {
      "cs": "/reklamace",
      "en": "/customer-service",
      "de": "/kundenservice",
      "fr": "/reclamation"
    },
    "eu-support": {
      "cs": "/podpora-eu",
      "en": "/eu-support",
      "de": "/eu-foerderprogramme",
      "fr": "/soutien-de-l-union-europeenne"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "Čeština",
    "files": [
      {
        "path": "lang/translations/baseCommon.ts"
      },
      {
        "path": "lang/translations/base.cs.ts"
      },
      {
        "path": "lang/translations/pages.cs.ts"
      },
      {
        "path": "lang/translations/signup.cs.ts"
      },
      {
        "path": "lang/translations/user.cs.ts"
      },
      {
        "path": "lang/translations/aboutCompany.cs.ts"
      },
      {
        "path": "lang/translations/forCustomers.cs.ts"
      },
      {
        "path": "lang/translations/emails.cs.ts"
      },
      {
        "path": "lang/translations/countries.cs.ts"
      },
      {
        "path": "lang/translations/breadcrumb.cs.ts"
      },
      {
        "path": "lang/translations/error.cs.ts"
      },
      {
        "path": "lang/translations/configurator.cs.ts"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "lang/translations/baseCommon.ts"
      },
      {
        "path": "lang/translations/base.en.ts"
      },
      {
        "path": "lang/translations/pages.en.ts"
      },
      {
        "path": "lang/translations/signup.en.ts"
      },
      {
        "path": "lang/translations/user.en.ts"
      },
      {
        "path": "lang/translations/aboutCompany.en.ts"
      },
      {
        "path": "lang/translations/forCustomers.en.ts"
      },
      {
        "path": "lang/translations/emails.en.ts"
      },
      {
        "path": "lang/translations/countries.en.ts"
      },
      {
        "path": "lang/translations/breadcrumb.en.ts"
      },
      {
        "path": "lang/translations/error.en.ts"
      },
      {
        "path": "lang/translations/configurator.en.ts"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "lang/translations/baseCommon.ts"
      },
      {
        "path": "lang/translations/base.de.ts"
      },
      {
        "path": "lang/translations/pages.de.ts"
      },
      {
        "path": "lang/translations/signup.de.ts"
      },
      {
        "path": "lang/translations/user.de.ts"
      },
      {
        "path": "lang/translations/aboutCompany.de.ts"
      },
      {
        "path": "lang/translations/forCustomers.de.ts"
      },
      {
        "path": "lang/translations/emails.de.ts"
      },
      {
        "path": "lang/translations/countries.de.ts"
      },
      {
        "path": "lang/translations/breadcrumb.de.ts"
      },
      {
        "path": "lang/translations/error.de.ts"
      },
      {
        "path": "lang/translations/configurator.de.ts"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "files": [
      {
        "path": "lang/translations/baseCommon.ts"
      },
      {
        "path": "lang/translations/base.fr.ts"
      },
      {
        "path": "lang/translations/pages.fr.ts"
      },
      {
        "path": "lang/translations/signup.fr.ts"
      },
      {
        "path": "lang/translations/user.fr.ts"
      },
      {
        "path": "lang/translations/aboutCompany.fr.ts"
      },
      {
        "path": "lang/translations/forCustomers.fr.ts"
      },
      {
        "path": "lang/translations/emails.fr.ts"
      },
      {
        "path": "lang/translations/countries.fr.ts"
      },
      {
        "path": "lang/translations/breadcrumb.fr.ts"
      },
      {
        "path": "lang/translations/error.fr.ts"
      },
      {
        "path": "lang/translations/configurator.fr.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
