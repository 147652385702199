export const notificationKind = {
  NOTIFICATION: 'notification',
  MODAL: 'modal',
  TOP_BAR: 'top-bar'
} as const

export const notificationPosition = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
} as const

export const notificationShowTo = {
  ALL: 'all',
  SIGNED_IN_USERS: 'signed-in-users'
} as const

export const notificationShowWhen = {
  EVERYTIME: 'everytime',
  ONCE_A_DAY: 'once-a-day',
  ONCE_A_WEEK: 'once-a-week',
  ONCE_A_MONTH: 'once-a-month'
} as const