import type { $Fetch, NitroFetchRequest, NitroFetchOptions } from 'nitropack'

export function baseRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    async get<T>(
      path: Array<string | number>,
      opts?: NitroFetchOptions<NitroFetchRequest, 'get'>
    ) {
      return fetch<T>(makeURL(path), {
        ...opts,
        method: 'GET',
      })
    },
    async post<T>(
      path: Array<string | number>,
      opts?: NitroFetchOptions<NitroFetchRequest, 'post'>
    ) {
      return fetch<T>(makeURL(path), {
        ...opts,
        method: 'POST',
      })
    },
  }
}

function makeURL(url: string | Array<string | number>): string {
  if (Array.isArray(url)) {
    url = url.join('/')
  } else if (url.startsWith('http')) {
    return url
  }
  return url
}
