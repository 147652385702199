import { joinURL, encodePath, encodeParam } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    format: 'f',
    fit: 'fit',
    width: 'w',
    height: 'h',
    resize: 's',
    quality: 'q',
    background: 'b',
  },
  joinWith: ',',
  formatter: (key, val) => encodeParam(val),
})

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = 'http://localhost:1337/uploads' } = {},
  ctx
) => {
  // just width and height keys are supported, if only one supplied, copy them and create resize modifier from them
  if (modifiers.width && !modifiers.height) {
    modifiers.height = modifiers.width
  }
  if (modifiers.height && !modifiers.width) {
    modifiers.width = modifiers.height
  }

  if (modifiers.width && modifiers.height) {
    modifiers.resize = `${modifiers.width}x${modifiers.height}`
    delete modifiers.width
    delete modifiers.height
  }

  const params = operationsGenerator(modifiers) || ''

  if (!baseURL) {
    baseURL = joinURL(ctx.options.nuxt.baseURL, '/_ipx')
  }

  if (src.startsWith('http')) {
    return {
      url: joinURL(encodePath(src), params),
    }
  } else {
    return {
      url: joinURL(baseURL, params, encodePath(src)),
    }
  }
}

export const validateDomains = true
export const supportsAlias = true
