<script setup lang="ts">
import type {
  ProductDetail,
  ProductWithProductLine,
  File,
  Currency,
  ApiOptional,
} from '~/types/api'

const props = defineProps<{
  products?: GridProducts
  sets?: GridSets
  subtitleAsTitle?: boolean
  dense?: boolean
}>()

const emit = defineEmits(['linkClicked'])

type Image = {
  url: ApiOptional<string>
  imageRatio: ApiOptional<number>
}

export type GridProducts = {
  nid: number
  title: ApiOptional<string>
  productLine: {
    title: ApiOptional<string>
    urlSlug: ApiOptional<string>
  }
  image: Image
}[]

export type GridSets = {
  nid: number
  title: ApiOptional<string>
  productLine: {
    title: ApiOptional<string>
    urlSlug: ApiOptional<string>
  }
  urlSlug: ApiOptional<string>
  image: Image
  readyForConfigurator: ApiOptional<boolean>
  minimalProductPrice?: ApiOptional<number>
  currency?: ApiOptional<Currency>
}[]

type GridItem = {
  title?: string
  subtitle?: string
  nid: number
  src?: string | null
  class: string[]
  type: number
  position2: number
  position3: number
  position4: number
  position6: number
  link?: string
  minimalFormattedPrice?: string
  readyForConfigurator?: boolean | null
}

function baseItem(
  nid: number,
  title?: string | null,
  subtitle?: string | null,
  image?: Image
): GridItem {
  return {
    title: title ?? undefined,
    subtitle: subtitle ?? undefined,
    nid,
    src: image?.url,
    class: image?.imageRatio
      ? ['row-span-1', 'col-span-1', 'max-sm:col-span-full']
      : ['row-span-1', 'col-span-2', 'max-sm:col-span-full'],
    type: image?.imageRatio ? 1 : 2,
    position2: 0,
    position3: 0,
    position4: 0,
    position6: 0,
  }
}

const mappedItems = computed(() => {
  const result: GridItem[] = []
  props.products?.forEach((product) => {
    const item = baseItem(
      product.nid,
      product.productLine?.title, // ProductDetail type is missing productLine
      product.title,
      product.image
    )
    // TODO: Products are missing productSet for now so we cant make link nor got minimal price or if it is ready for configurator
    // right now we are not showing products anywhere - leaving compatibility for future
    result.push(item)
  })
  props.sets?.forEach((set) => {
    const item = baseItem(set.nid, set.productLine?.title, set.title, set.image)
    item.link =
      set.productLine?.urlSlug && set.urlSlug
        ? `/${set.productLine.urlSlug}/${set.urlSlug}`
        : undefined
    item.minimalFormattedPrice = set.minimalProductPrice
      ? formatPrice(set.minimalProductPrice, set.currency)
      : undefined
    item.readyForConfigurator = set.readyForConfigurator
    result.push(item)
  })
  return result
})

// Needs to be here because Tailwind treeshaking doesn't work with dynamic classes
const whitelist = [
  '2xl:col-span-6',
  '2xl:col-span-5',
  '2xl:col-span-4',
  '2xl:col-span-3',
  'xl:col-span-4',
  'xl:col-span-3',
  'xl:col-span-2',
  'lg:col-span-3',
  'lg:col-span-2',
  'sm:col-span-2',
  'sm:col-span-1',
]

const gridItems = computed(() => {
  const result: GridItem[] = []
  for (let i = 0; i < mappedItems.value.length; i++) {
    const item = mappedItems.value[i]
    let cls = ''

    let position2 =
      result[result.length - 1]?.position2 + item.type || item.type
    let position3 =
      result[result.length - 1]?.position3 + item.type || item.type
    let position4 =
      result[result.length - 1]?.position4 + item.type || item.type
    let position6 =
      result[result.length - 1]?.position6 + item.type || item.type

    // Adjust last item if needed
    // if (i === productsPrepare.length - 1) {
    //   cls += ` 2xl:col-span-${6 - ((result[result.length - 1]?.position6 || 0) % 6)}`
    //   cls += ` xl:col-span-${4 - ((result[result.length - 1]?.position4 || 0) % 4)}`
    //   cls += ` lg:col-span-${3 - ((result[result.length - 1]?.position3 || 0) % 3)}`
    //   cls += ` sm:col-span-${2 - ((result[result.length - 1]?.position2 || 0) % 2)}`

    //   result.push({
    //     ...productsPrepare[i],
    //     class: [...product.class, cls],
    //     position2,
    //     position3,
    //     position4,
    //     position6,
    //   })

    //   break
    // }

    if (item.type === 1) {
      if (position2 % 2 === 1 && mappedItems.value[i + 1]?.type === 2) {
        position2 += 1
        cls += ' sm:col-span-2'
      } else {
        cls += ' sm:col-span-1'
      }
    }

    if (item.type === 1) {
      if (position3 % 3 === 2 && mappedItems.value[i + 1]?.type === 2) {
        position3 += 1
        cls += ' lg:col-span-2'
      } else {
        cls += ' lg:col-span-1'
      }
    } else if (item.type === 2) {
      if (position3 % 3 === 2 && mappedItems.value[i + 1]?.type === 2) {
        position3 += 1
        cls += ' lg:col-span-3'
      } else {
        cls += ' lg:col-span-2'
      }
    }

    if (item.type === 1) {
      if (position4 % 4 === 3 && mappedItems.value[i + 1]?.type === 2) {
        position4 += 1
        cls += ' xl:col-span-2'
      } else {
        cls += ' xl:col-span-1'
      }
    } else if (item.type === 2) {
      if (position4 % 4 === 3 && mappedItems.value[i + 1]?.type === 2) {
        position4 += 1
        cls += ' xl:col-span-3'
      } else {
        cls += ' xl:col-span-2'
      }
    }

    if (item.type === 1) {
      if (position6 % 6 === 5 && mappedItems.value[i + 1]?.type === 2) {
        position6 += 1
        cls += ' 2xl:col-span-2'
      } else {
        cls += ' 2xl:col-span-1'
      }
    } else if (item.type === 2) {
      if (position6 % 6 === 5 && mappedItems.value[i + 1]?.type === 2) {
        position6 += 1
        cls += ' 2xl:col-span-3'
      } else {
        cls += ' 2xl:col-span-2'
      }
    }

    result.push({
      ...mappedItems.value[i],
      class: [...item.class, cls],
      position2,
      position3,
      position4,
      position6,
    })
  }
  return result
})

const localePath = useLocalePath()

const userStore = useUser()
const { showPrices } = storeToRefs(userStore)
</script>

<template>
  <div class="mx-auto overflow-hidden border-y-4 border-white 2xl:w-full">
    <div
      class="-mb-1 -ml-2 -mr-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6"
    >
      <UiCard
        v-for="item in gridItems"
        :key="item.nid"
        class="h-full w-full rounded-none border-b-4 border-l-0 border-r-4 border-t-0 border-white bg-neutral-100 p-2"
        :class="item.class"
      >
        <NuxtLink
          :to="item.link ? localePath(item.link) : undefined"
          class="no-underline"
          @click="emit('linkClicked')"
        >
          <div
            class="group flex h-full flex-col gap-3 p-2 hover:bg-white"
            :class="{
              '!flex-row justify-between max-md:items-center md:!flex-col':
                props.dense,
            }"
          >
            <div>
              <p class="font-pragmatica-ext text-xl font-bold">
                {{ !props.subtitleAsTitle ? item.title : item.subtitle }}
              </p>
              <p
                v-if="!props.subtitleAsTitle"
                class="font-pragmatica-ext font-bold"
              >
                {{ item.subtitle }}
              </p>
            </div>
            <div
              class="h-96 2xl:h-60"
              :class="{ '!h-28 md:!h-96 2xl:!h-60': dense }"
            >
              <NuxtImg
                v-if="item.src"
                :src="withHost(item.src)"
                sizes="100vw sm:300px"
                class="h-full w-full object-contain"
              />
              <div
                v-else
                class="flex h-full w-full items-center justify-center"
              >
                <Icon class="text-xl text-black" :name="'fa6-solid:image'" />
              </div>
            </div>
            <div
              class="flex min-h-8 flex-row items-end justify-between gap-5"
              :class="{ 'max-md:hidden': props.dense }"
            >
              <p
                v-if="showPrices && item.minimalFormattedPrice"
                class="font-pragmatica-ext font-bold"
              >
                {{ $t('base.products.from') }} {{ item.minimalFormattedPrice }}
              </p>
              <div
                v-if="item.readyForConfigurator"
                class="ml-auto aspect-square rounded-full bg-white group-hover:bg-neutral-100"
              >
                <Icon name="fa6-regular:pen-to-square" class="m-2"></Icon>
              </div>
            </div>
          </div>
        </NuxtLink>
      </UiCard>
    </div>
  </div>
</template>
