<script setup lang="ts">
const props = defineProps<{
  phone?: boolean
}>()

function toggleSearch() {
  if (searching.value) {
    searching.value = false
  } else {
    searching.value = true
  }
}

const searching = defineModel<boolean>()
</script>

<template>
  <div class="flex cursor-pointer items-center gap-1" @click="toggleSearch">
    <template v-if="!props.phone">
      <UiAnimatedUnderlineText>
        <div class="text-base" :class="{ 'text-xl': props.phone }">
          {{ $t('base.search.title') }}
        </div>
      </UiAnimatedUnderlineText>
    </template>
    <Icon
      v-if="!searching"
      name="ri:search-line"
      :size="props.phone ? '30' : '20'"
    ></Icon>
    <Icon
      v-else
      name="ic:baseline-close"
      :size="props.phone ? '40' : '25'"
      class="-mr-[5px]"
    ></Icon>
  </div>
</template>

<style scoped></style>
