<script setup lang="ts">
import { debounce } from 'perfect-debounce'

const emit = defineEmits(['close'])

const search = ref('')
const open = ref(false)

const placeHolder = computed(() => {
  return open.value
    ? 'base.search.placeholder'
    : 'base.search.autocomplete.placeholder'
})

const debouncedUpdate = debounce(() => {
  if (search.value.length > 1) {
    refresh()
  }
}, 300)

watch(search, () => {
  debouncedUpdate()
})

const {
  data: productLines,
  status,
  refresh,
} = await useLazyAsyncData(
  'search',
  () => createSearchRepository().search(search.value),
  {
    immediate: false,
  }
)

const noSearchResults = computed(() => {
  return (productLines.value?.items ?? []).length === 0

  // TODO add references and fulltext check
})

const noProductSets = computed(() => {
  return !(productLines.value?.items ?? []).some(
    (productLine) => productLine.productSets.length > 1
  )
})

function close() {
  open.value = false
  emit('close')
}
</script>

<template>
  <div>
    <form @submit.prevent class="mx-auto">
      <div class="flex w-full px-2">
        <UInput
          :ui="{
            width: 'w-full left-0 !-ml-[8px] rounded-0 py-2',
            shadow: '',
            ring: '',
            rounded: '',
          }"
          color="primary"
          v-model="search"
          class="h-8 w-full border-black"
          size="lg"
          :placeholder="$t(placeHolder)"
          @focusin="open = true"
        />
      </div>
    </form>
    <div
      v-if="open"
      class="z-50 w-full pt-5 lg:absolute lg:left-0 lg:top-[128px] lg:w-screen lg:pt-0"
    >
      <div class="mb-[4px] w-full border-b-4 border-white bg-neutral-100">
        <div
          class="font-pragmatica-ext container w-full overflow-hidden py-10 text-xl lg:py-16"
        >
          <div
            v-if="status === 'pending' && search.length > 1"
            class="text-center"
          >
            {{ $t('base.search.searching') }}
          </div>
          <div v-else-if="search.length < 2" class="text-center">
            {{ $t('base.search.minimalLength') }}
          </div>
          <div v-else-if="noSearchResults" class="text-center">
            {{ $t('base.search.empty') }}
          </div>
          <template v-else>
            <SearchProductLines
              @link-clicked="close()"
              :product-lines="productLines"
              short
            />
            <SearchProductSets
              v-if="!noProductSets"
              @link-clicked="close()"
              :product-lines="productLines"
              short
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
