import type { Redirect } from '~/types/api'
import type { QueryParams } from '~/composables/useApi'
import { APIFilters, getBaseFilter } from '~/utils/APIFilters'

export function createRedirectsRepository() {
  const { $api } = useNuxtApp()
  const repository = baseRepository($api)

  const { $i18n } = useNuxtApp()

  const { locale } = $i18n

  const DOMAIN = 'redirects'

  return {
    async getAll(params?: QueryParams) {
      if (!params) {
        params = {
          filter: APIFilters.makeFilter(getBaseFilter(locale.value, true)),
          sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
        }
      }
      return repository.get<Redirect[]>([DOMAIN], { params })
    },
  }
}
