<script setup lang="ts">
import type { GridSets } from '~/components/Products/ProductsGrid.vue'
import type { ProductLineSearchPagedResult } from '~/types/api'

const props = defineProps<{
  productLines: ProductLineSearchPagedResult
  short?: boolean
}>()

const productSets = computed(() => {
  const sets: GridSets = []
  props.productLines.items.forEach((line) => {
    line.productSets.forEach((set) => {
      sets.push({
        productLine: {
          title: line.title,
          urlSlug: line.urlSlug,
        },
        nid: set.nid,
        title: set.title,
        urlSlug: set.urlSlug,
        image: {
          url: set.image?.url,
          imageRatio: set.image?.imageRatio,
        },
        minimalProductPrice: set.minimalProductPrice,
        currency: set.currency,
        readyForConfigurator: set.readyForConfigurator,
      })
    })
  })
  return sets
})

const emit = defineEmits(['linkClicked'])
</script>

<template>
  <div class="pb-10 xl:pb-20">
    <h2 class="font-pragmatica-ext mb-8 text-2xl font-bold">
      {{ $t('base.search.productSets') }}
    </h2>
    <ProductsGrid
      @linkClicked="emit('linkClicked')"
      v-if="productSets.length"
      :sets="props.short ? productSets.slice(0, 6) : productSets"
      dense
    />
  </div>
</template>
