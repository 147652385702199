export default defineNuxtRouteMiddleware((to, from) => {
  const {
    public: { baseUrl },
  } = useRuntimeConfig()

  const url = useRequestURL()
  const hostname = url.hostname

  if (
    hostname === 'localhost' ||
    (url.pathname !== '/' && url.pathname !== '') ||
    url.pathname.length !== 0
  ) {
    return
  }

  // Other methods are handled by i18n module
  const langBrowser = useBrowserLocale()
  const langCookieI18n = useCookieLocale() // Doesn't work - Open issue https://github.com/nuxt-modules/i18n/issues/2975
  const langCookie = useCookie('i18n_redirected') // Use this instead
  const langParam = url.searchParams.get('lang') // TODO: Check if needed; Probably not needed

  if (langCookie.value || langBrowser) {
    return
  }

  const languages = {
    en: '/en',
    cs: '/cs',
    de: '/de',
    fr: '/fr',
  }

  const arr = hostname.split('.')
  const ltd = arr[arr.length - 1]

  switch (ltd) {
    case 'com':
      return
    case 'cz':
    case 'sk':
      return navigateTo(baseUrl + languages.cs, { external: true })
    case 'de':
    case 'at':
    case 'ch':
      return navigateTo(baseUrl + languages.de, { external: true })
    case 'fr':
      return navigateTo(baseUrl + languages.fr, { external: true })
    default:
      return navigateTo(baseUrl + languages.en, { external: true })
  }
})
