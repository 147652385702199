import type { NuxtApp } from 'nuxt/app'
import { createStorage } from 'unstorage'
import type { Storage, StorageValue } from 'unstorage'
import indexeDbDriver from 'unstorage/drivers/indexedb'
import memory from 'unstorage/drivers/memory'
import type { RouteLocationNormalized } from 'vue-router'

const TTL = 1 * 60 * 1000 // 1 minute

async function handleRedirect(
  to: RouteLocationNormalized,
  storage: Storage<StorageValue>
) {
  const locale = '/' + to.fullPath.split('/')[1]
  const pathWithoutLocale = '/' + to.fullPath.split('/').slice(2).join('/')

  const redirectTo = await storage.getItem(pathWithoutLocale)

  if (redirectTo && typeof redirectTo === 'string') {
    return await navigateTo(locale + redirectTo, { redirectCode: 301 })
  }

  return true
}

async function refreshRedirects(
  storage: Storage<StorageValue>,
  nuxtApp: NuxtApp
) {
  const timestamp = await storage.getItem<number>('timestamp')

  const remainingTime = timestamp && timestamp - new Date().getTime()

  if (remainingTime && remainingTime > 0) {
    return
  }

  const redirects = await nuxtApp.runWithContext(
    async () => await createRedirectsRepository().getAll()
  )

  for (const redirect of redirects) {
    await storage.setItem(redirect.urlFrom, redirect.urlTo)
  }

  await storage.setItem('timestamp', new Date().getTime() + TTL)
}

const serverStorage = createStorage({
  driver: memory(),
})

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  if (
    import.meta.client &&
    nuxtApp.isHydrating &&
    nuxtApp.payload.serverRendered
  )
    return

  let storage: Storage<StorageValue> | null = null

  if (import.meta.client) {
    const clientStorage = createStorage({
      driver: indexeDbDriver({
        base: 'redirects',
        dbName: 'redirects',
        storeName: 'redirects',
      }),
    })

    storage = clientStorage
  }

  if (import.meta.server) {
    storage = serverStorage
  }

  if (storage) {
    await refreshRedirects(storage, nuxtApp)
    return await handleRedirect(to, storage)
  }

  return true
})
