import { defineStore } from 'pinia'

export const useAlertsStore = defineStore(
  'alerts',
  () => {
    const visible = ref(false)
    const messages = ref<string[]>([])

    const isVisible = computed(() => visible.value)

    function setVisible(value: boolean): void {
      visible.value = value
    }

    function showAlert(alertMessages: string[]): void {
      messages.value = alertMessages
      visible.value = true
    }

    return { visible, messages, isVisible, setVisible, showAlert }
  },
  {
    persist: {
      storage: persistedState.cookies,
    },
  }
)
