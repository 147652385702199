export const staticPageKeys = {
  CONTACT: 'contact',
  PRIVACY_POLICY: 'privacyPolicy',
  SALES_CONDITIONS: 'salesConditions',
  CUSTOMER_SERVICE: 'customerService',
  EU_SUPPORT: 'euSupport',
}

type Keys = keyof typeof staticPageKeys
export type StaticPageKeys = (typeof staticPageKeys)[Keys]

export type StaticPageRecord = {
  links: {
    cs: string
    en: string
    de: string
    fr: string
  }
  component: string | null
}

export const staticPages = {
  contact: {
    links: {
      cs: 'kontakt',
      en: 'contact',
      de: 'kontakt',
      fr: 'contact',
    },
    component: 'Contact',
  },
  privacyPolicy: {
    links: {
      cs: 'ochrana-osobnich-udaju',
      en: 'privacy-policy',
      de: 'datenschutzbereich',
      fr: 'politique-de-confidentialite',
    },
    component: 'PrivacyPolicy',
  },
  salesConditions: {
    links: {
      cs: 'obchodni-podminky',
      en: 'sales-conditions',
      de: 'geschaeftsbedingungen',
      fr: 'termes-et-conditions',
    },
    component: 'SalesConditions',
  },
  customerService: {
    links: {
      cs: 'reklamace',
      en: 'customer-service',
      de: 'kundenservice',
      fr: 'reclamation',
    },
    component: 'CustomerService',
  },
  euSupport: {
    links: {
      cs: 'podpora-eu',
      en: 'eu-support',
      de: 'eu-foerderprogramme',
      fr: 'soutien-de-l-union-europeenne',
    },
    component: 'EUSupport',
  },
} as Record<StaticPageKeys, StaticPageRecord>

export function useStaticPages() {
  const { locale } = useI18n()

  function getStaticPageSlug(staticPageKey: StaticPageKeys): string {
    return staticPages[staticPageKey].links[locale.value]
  }

  function getComponentForStaticPageSlug(
    staticPageSlug: string
  ): string | null {
    const pageRecords = Object.values(staticPages)
    for (const page of pageRecords) {
      const links = Object.values(page.links)
      if (links.includes(staticPageSlug)) {
        return page.component
      }
    }
    return null
  }

  return {
    getStaticPageSlug,
    getComponentForStaticPageSlug,
  }
}
