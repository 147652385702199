<script setup lang="ts">
const props = defineProps({
  reset: {
    type: Boolean,
    default: false,
  },
  amount: {
    type: Number,
    default: 0,
  },
  rightPaneClass: {
    type: String,
    default: '',
  },
})

const transform = `translateY(${props.amount}%)`

const rightPane = ref()
const animate = ref(false)

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    // forEach fixes issue of entry.isIntersecting being false on first trigger after page reload
    entries.forEach((entry) => {
      animate.value = entry.isIntersecting
      if (animate.value && !props.reset) {
        observer.disconnect()
      }
    })
  })

  observer.observe(rightPane.value.firstElementChild) // we want to observe what is slotted into the right pane
})

// Instead of Bootstrap's lg breakpoint (992px) uses Tailwind's lg breakpoint (1024px)
// -> will need to be changed if we decide to use different breakpoints
</script>

<template>
  <div>
    <slot name="left" />
    <div ref="rightPane" :class="rightPaneClass">
      <slot name="right" :animation-class="{ 'animated-component': animate }" />
    </div>
  </div>
</template>

<style lang="sass">
@media (min-width: 1024px)
  .animated-component
    transform: v-bind(transform)
    transition: transform .6s ease .6s
</style>
