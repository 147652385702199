export default defineNuxtPlugin((nuxtApp) => {
  const cookieControl = useCookieControl()
  const gtag = useGtag()

  if (cookieControl.cookiesEnabledIds.value?.includes('gtag')) {
    gtag.grantConsent('GTM-KZ36GBWJ')
  } else {
    gtag.revokeConsent('GTM-KZ36GBWJ')
  }
})
