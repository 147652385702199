<script setup lang="ts">
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  messages: {
    type: Array as PropType<String[]>,
    default: () => [],
  },
})

const emit = defineEmits(['close'])
</script>

<template>
  <UiModal :model-value="show">
    <div
      v-for="(message, idx) in messages"
      :key="idx"
      class="whitespace-normal pb-4 text-lg font-semibold"
    >
      {{ message }}
    </div>
    <div class="px-8 pt-8">
      <button
        class="w-full border-2 border-black px-8 py-4 text-xl font-bold"
        @click="emit('close')"
      >
        OK
      </button>
    </div>
  </UiModal>
</template>

<style scoped></style>
